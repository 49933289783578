export default function () {
  return {
    confirmNewTicket: false,
    createdTicket: false,
    reviewTicket: false,
    uploadAttachment: false,
    detailAttachment: false,
    thx: false,
    ticket: {},
    confirm: false,
    modalError: false,
    instrument: false,
    certificate: false,
    ticket_type: false,
    modalVideo: false,
    info: false,
    caf: false,
    bot: false,
    cafPop: false
  }
}
