<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-lg"
    >
      <div class="bg-white" style="border-radius: 10px">
        <div class="flex justify-between items-start">
          <div class="max-h-full w-full px-10 py-8">
            <div class="text-center" v-if="action == 'cancel'">
              Are you sure you want to cancel ticket
            </div>
            <div class="text-center" v-if="action == 'deleteAttachment'">
              Are you sure you want to delete attachment?
            </div>
            <div class="text-center mt-1" v-if="action == 'cancel'">
              <span class="font-semibold">{{ ticket ? ticket.number : '...' }}?</span>
            </div>

            <div class="flex space-x-3 justify-center mt-5">
              <button
                class="btn btn-error text-white border-0"
                :class="loading == true ? 'loading' : ''"
                style="height: 31px; border-radius: 20px; min-width: 102px"
                @click="confirm"
                :disabled="loading"
              >
                Ok
              </button>
              <button
                @click="close"
                class="btn btn-ghost text-black capitalize font-bold hover:border-transparent"
                style="height: 31px; border-radius: 20px; min-width: 102px"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgClear from '@/assets/icons/icon-close.svg'

import WaveBack from '@/assets/images/wave-back.png'
import WaveFront from '@/assets/images/wave-front.png'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'ticket-confirm',
  data() {
    return {
      SvgClear,
      WaveBack,
      WaveFront,
      loading: false
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_CONFIRM']),
    ...mapActions('ticket', ['TICKET_CANCEL', 'DELETE_TICKET_ATTACHMENTS']),
    close() {
      this.MODAL_CONFIRM(false)
      this.loading = false
    },
    confirm() {
      this.loading = true

      if (this.action == 'cancel') this.cancelTicket()

      if (this.action == 'deleteAttachment') this.deleteAttachment()
    },
    async cancelTicket() {
      let res = await this.TICKET_CANCEL(this.ticket.id)

      if (res) {
        this.$toast.info('Success cancel ticket')
        this.$router.push({ name: 'ticket.index' })
        this.MODAL_CONFIRM(false)
      }

      this.loading = false
    },
    async deleteAttachment() {
      let data = this.attachments

      if (Array.isArray(data) && data.length) {
        data.forEach(d => {
          if (d.checked) {
            this.DELETE_TICKET_ATTACHMENTS({
              ticket_id: this.ticket.id,
              attachment_id: d.id
            })
          }
        })
      }

      this.close()
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.confirm
      },
      set: function (value) {
        this.MODAL_CONFIRM(value)
      }
    },
    ...mapState({
      action: state => state.ticket.action,
      ticket: state => state.ticket.ticket,
      attachments: state => state.ticket.attachment_deletes
    })
  }
}
</script>
