const moment = require('moment')

export const GET_TICKETS = (state, data) => {
  state.tickets = data
}

export const GET_TICKET_TYPES = (state, data) => {
  state.ticket_types = data
}

export const TICKET_FORM = (state, val) => {
  state.form = val
}

export const TICKET_DETAIL = (state, val) => {
  state.ticket = val
}

export const TICKET_ACTION = (state, data) => {
  state.action = data
}

export const GET_TICKET_COUNT = (state, data) => {
  state.count = data
}

export const TICKET_STATUSES = (state, data) => {
  state.ticketStatuses = data
}

export const PRE_OPEN_TICKET = (state, data) => {
  state.pre_open = data
}

export const TICKET_SERVICE_REPORT = (state, data) => {
  state.service_reports = data
}

export const GET_INSTRUMENTS = (state, data) => {
  state.instruments = data
}

export const GET_DIVISIONS = (state, data) => {
  state.divisions = data
}

export const GET_REQUEST_TYPE = (state, data) => {
  state.request_types = data
}

export const GET_DEPARTMENTS = (state, data) => {
  state.departments = data
}

export const GET_TICKET_ATTACHMENTS = (state, data) => {
  state.attachments = data
}

export const ATTACHMENT_TYPES = (state, data) => {
  state.attachment_types = data.types ? data.types : []
}

export const DETAIL_ATTACHMENT = (state, data) => {
  state.attachment = data
}

export const ATTACHMENT_DELETE = (state, data) => {
  state.attachment_deletes = data
}

export const TICKET_TIME = (state, data) => {
  state.ticketTime = `tt-${data}`
}

export const ALL_CHATS = (state, data) => {
  state.chats = data
}

export const PUSH_CHATS = (state, data) => {
  let temp = state.chats

  temp.push(data)

  state.chats = temp
}

export const AI_RESET = state => {
  state.aiTicket = {
    id: null,
    number: null
  }
  state.aiChats = []
}

export const AI_TYPED = (state, data) => {
  state.aiTyped = data
}

export const AI_SEND = (state, data) => {
  state.aiTicket = data
}

export const AI_CHAT = (state, val) => {
  state.aiChats.push(val)
  state.aiTicket.lastAction = moment()
}

export const AI_HISTORY_CHAT = (state, data) => {
  data.shift()
  state.aiChats = data
}
