import ApiRequest from '../../services/apiRequest'

export const GET_NOTIFICATIONS = async context => {
  return await ApiRequest.getNotification()
    .then(response => {
      context.commit('GET_NOTIFICATIONS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const READ_NOTIFICATION = async (context, val) => {
  return await ApiRequest.readNotification(val)
    .then(response => {
      context.dispatch('GET_NOTIFICATIONS')
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_BROADCASTS = async context => {
  return await ApiRequest.getBroadcast()
    .then(response => {
      context.commit('GET_BROADCASTS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_BROADCAST_COUNT = async context => {
  return await ApiRequest.getBroadcastCount()
    .then(response => {
      context.commit('GET_BROADCAST_COUNT', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const SET_BROADCAST = (context, val) => {
  context.commit('SET_BROADCAST', val)
}

export const GET_POINTS = async (context, params) => {
  return await ApiRequest.getPoint(params)
    .then(response => {
      context.commit('GET_POINTS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TOTAL_POINTS = async context => {
  return await ApiRequest.totalPoint()
    .then(response => {
      context.commit('TOTAL_POINTS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
