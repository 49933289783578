<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-md"
    >
      <div class="bg-white sm:rounded-4xl px-5 py-2">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <!--  -->
            <div class="text-center">
              <div class="realtive font-bold text-xl text-herror mt-4 z-10">
                Please close ticket first!
              </div>
              <div class="relative font-medium z-10 text-md text-hsub mt-1">
                There are tickets that have not been closed
              </div>
            </div>
            <div class="text-center mt-7 pb-5">
              <button
                class="btn rounded-full btn-error text-white capitalize z-10 border-0"
                style="width: 180px"
                @click="close"
              >
                Close Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'modal-error',
  methods: {
    ...mapActions('modal', ['MODAL_ERROR']),
    close() {
      this.MODAL_ERROR(false)
      this.$router.push({
        name: 'ticket.index',
        query: { status: 'done', offset: 0, limit: 9, ticket_type: 0, t: new Date().getTime() }
      })
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.modalError
      },
      set: function (value) {
        this.MODAL_ERROR(value)
      }
    }
  }
}
</script>
