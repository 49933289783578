export const homeCount = state => {
  let data = state.count
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      if (d.status == 'new') {
        temp[0] = {
          count: d.count,
          status: d.status
        }
      } else if (d.status == 'confirmed') {
        temp[1] = {
          count: d.count,
          status: d.status
        }
      } else if (d.status == 'started') {
        temp[2] = {
          count: d.count,
          status: d.status
        }
      }
    })
  }

  return temp
}

export const listInstrument = state => {
  let data = state.instruments
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.label = d.serial_number
      temp.push(d)
    })
  }

  return temp
}

export const listRequestType = state => {
  let data = state.request_types
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.label = d.name
      temp.push(d)
    })
  }

  return temp
}

export const listDepartment = state => {
  let data = state.departments
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.label = d.name
      temp.push(d)
    })
  }

  return temp
}

export const listDivision = state => {
  let data = state.divisions
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.label = d.name
      temp.push(d)
    })
  }

  return temp
}

export const findInstrument = state => val => {
  let data = state.instruments

  let temp = data.find(obj => {
    return obj.id == val
  })

  return temp
}

export const findTicketType = state => val => {
  let data = state.menus

  let temp = data.find(obj => {
    return obj.id == val
  })

  return temp
}

export const findDivision = state => val => {
  let data = state.divisions

  let temp = data.find(obj => {
    return obj.id == val
  })

  return temp
}

export const findDepartment = state => val => {
  let data = state.departments

  let temp = data.find(obj => {
    return obj.id == val
  })

  return temp
}

export const listProgress = state => {
  let temp = state.ticketStatuses.sort(
    (a, b) => new Date(b.created_at.date) - new Date(a.created_at.date)
  )

  return temp
}

export const acceptFile = state => {
  let temp = []

  if (Array.isArray(state.attachment_types) && state.attachment_types.length) {
    state.attachment_types.forEach(at => {
      temp.push(`.${at}`)
    })
  }

  let acc = temp.toString()

  return acc
}

export const attachments = state => {
  let data = state.attachments
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.checked = false
      temp.push(d)
    })
  }

  return temp
}

export const ticketTypes = state => {
  let data = state.ticket_types

  let temp = {}
  temp.menus = [
    {
      name: 'All',
      sub: 'All type',
      to: 'all',
      id: 0
    }
  ]

  temp.technical = []
  temp.nontechnical = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      if (d.id == 1) {
        temp.menus.push({
          name: d.separator.name,
          id: 1,
          sub: d.name,
          alias: d.alias,
          to: 'open.technical',
          show: ['division', 'instrument', 'staff', 'phone', 'description']
        })

        temp.technical.push({
          id: d.id,
          name: d.separator.name,
          to: 'open.technical',
          alias: d.alias,
          group: d.separator.group
        })
      }

      if (d.id == 2) {
        temp.menus.push({
          name: d.separator.name,
          id: 2,
          sub: d.name,
          alias: d.alias,
          to: 'open.request',
          show: ['division', 'instrument', 'staff', 'phone', 'description', 'request_type']
        })

        temp.technical.push({
          id: d.id,
          name: d.separator.name,
          to: 'open.request',
          alias: d.alias,
          group: d.separator.group
        })
      }

      if (d.id == 3) {
        temp.menus.push({
          name: d.separator.name,
          id: 3,
          sub: d.name,
          alias: d.alias,
          to: 'open.nontechnical',
          show: ['department', 'staff', 'phone', 'description', 'picture']
        })
        temp.nontechnical.push({
          id: d.id,
          name: d.separator.name,
          to: 'open.nontechnical',
          alias: d.alias,
          group: d.separator.group
        })
      }

      if (d.id == 4) {
        temp.menus.push({
          name: d.separator.name,
          id: 4,
          sub: d.name,
          alias: d.alias,
          to: 'open.nontechnical',
          show: ['department', 'staff', 'phone', 'description', 'picture']
        })
        temp.nontechnical.push({
          id: d.id,
          name: d.separator.name,
          to: 'open.nontechnical',
          alias: d.alias,
          group: d.separator.group
        })
      }
    })
  }

  return temp
}
