<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto sm:h-auto sm:max-w-xl"
    >
      <div class="bg-white h-fit sm:rounded-21px">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <div class="flex justify-end items-center px-5 pt-5">
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <div class="h-20.1">
              <img
                :src="emoticons[star - 1]"
                class="mx-auto -mt-3 z-10 relative animate-rating"
                :key="star"
              />
            </div>
            <div class="text-center font-Nerko text-hblue mt-1">
              {{ textIcon[star - 1] }}
            </div>

            <div class="text-md text-black text-center mt-3 z-10 relative">
              How was your experience?
            </div>

            <div
              class="grid grid-cols-5 px-5 gap-3 sm:gap-5 place-items-center mx-auto mt-3 z-10 relative"
              style="max-width: 400px"
            >
              <div v-for="n in 5" :key="n">
                <IconStar
                  class="fill-current cursor-pointer w-full h-full max-h-17.69 max-w-17.69"
                  @click="star = n"
                  :class="n <= star ? `css-star-${star}` : 'text-hgray3'"
                />
              </div>
            </div>

            <div class="form-control z-20 relative mt-6 mx-auto px-5" style="max-width: 450px">
              <treeselect
                ref="reg-cus"
                class="h-9"
                v-model="templateSelected"
                :multiple="false"
                :options="templateDescriptions"
                :disabled="loading"
              />
            </div>

            <div class="form-control z-10 relative mt-4 mb-2 mx-auto px-5" style="max-width: 450px">
              <textarea
                class="textarea h-24 textarea-bordered placeholder"
                placeholder="Type note here..."
                v-model="description"
                :disabled="!templateSelected"
              ></textarea>
              <div class="inline-flex whitespace-nowrap mt-1" v-if="errorDescription">
                <img :src="SvgWarning" class="mr-2" />
                <span
                  class="text-red-500 italic"
                  style="font-size: 14px; line-height: 1; font-weight: 300"
                >
                  Comment is required!
                </span>
              </div>
            </div>

            <div class="relative z-10 text-center mb-6 mt-7 px-5">
              <button
                class="btn btn-secondary hover:bg-htealdark rounded-full capitalize h-10 z-5 sm:justify-self-start hover:border-transparent"
                style="width: 180px"
                @click="submit"
                :class="loadSubmit == true ? 'loading' : ''"
                :disabled="loadSubmit"
              >
                Close Ticket
              </button>
            </div>

            <div class="relative hidden sm:block">
              <img :src="WaveBack" class="absolute bottom-0" />
              <img :src="WaveFront" class="absolute bottom-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'

import EmoticonOne from '@/assets/icons/emoticon-1star.svg'
import EmoticonTwo from '@/assets/icons/emoticon-2star.svg'
import EmoticonThree from '@/assets/icons/emoticon-3star.svg'
import EmoticonFour from '@/assets/icons/emoticon-4star.svg'
import EmoticonFive from '@/assets/icons/emoticon-5star.svg'

import IconStar from '@/assets/icons/icon-star.svg?inline'
import SvgWarning from '@/assets/icons/icon-warning.svg'

import WaveBack from '@/assets/images/wave-back.png'
import WaveFront from '@/assets/images/wave-front.png'

import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'ticket-review',
  components: {
    IconStar,
    Treeselect
  },
  data() {
    return {
      SvgClear,
      SvgWarning,

      WaveBack,
      WaveFront,
      emoticons: [EmoticonOne, EmoticonTwo, EmoticonThree, EmoticonFour, EmoticonFive],
      textIcon: ['Disappointed', 'Bad', 'Not Good', 'Good', 'Awesome'],
      description: '',
      star: 5,
      templateSelected: null,
      loading: false,
      loadSubmit: false,
      templateDescriptions: [],
      errorDescription: false
    }
  },
  watch: {
    star: function (val) {
      this.getDraft({
        rating: val
      })
    },
    templateSelected: function (val) {
      this.description = val
    },
    loggedIn: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getDraft({
            rating: this.star
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_REVIEW_TICKET', 'MODAL_THX']),
    ...mapActions('ticket', ['COMMENT_DRAFTS', 'TICKET_CLOSE']),
    close() {
      this.MODAL_REVIEW_TICKET(false)
    },
    async getDraft(val) {
      this.loading = true
      this.templateSelected = null
      let res = await this.COMMENT_DRAFTS(val)
      if (res) {
        let temp = []
        if (Array.isArray(res) && res.length) {
          res.forEach(d => {
            temp.push({
              id: d.draft,
              label: d.draft
            })
          })
        }
        this.templateDescriptions = temp
      }

      this.loading = false
    },
    async submit() {
      this.loadSubmit = true
      this.errorDescription = false
      const result = this.description.length ? true : false
      if (!result) {
        this.loadSubmit = false
        this.errorDescription = true
        return
      }

      await this.TICKET_CLOSE({
        ticket_id: this.ticket.id,
        rating: this.star,
        comment: this.description
      })

      this.MODAL_REVIEW_TICKET(false)
      this.MODAL_THX(true)

      this.loadSubmit = false
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.reviewTicket
      },
      set: function (value) {
        this.MODAL_REVIEW_TICKET(value)
      }
    },
    ...mapState({
      ticket: state => state.ticket.ticket,
      loggedIn: state => state.auth.loggedIn
    })
  }
}
</script>
