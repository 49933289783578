<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-md"
    >
      <div class="bg-white sm:rounded-lg">
        <div class="py-5 flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <div class="flex justify-between items-center mb-5 px-5">
              <h3 class="text-xl font-semibold">Confirmation</h3>
            </div>

            <div class="flex px-5">
              <div class="mr-3" v-if="ticketType">
                <TicketRequest v-if="ticketType.id == 2" class="w-11.3 h-11.4" />
                <TicketTechnical v-else-if="ticketType.id == 1" class="w-11 h-11.3" />
                <TicketNTComplaint v-else-if="ticketType.id == 3" class="w-11 h-11.3" />
                <TicketNTRequest v-else class="w-11.3 h-11.3" />
              </div>

              <div v-if="ticketType">
                <p
                  class="text-2xl font-semibold leading-1 mt-1"
                  :class="{
                    'text-hservice': ticketType.id == 1,
                    'text-hother': ticketType == 2,
                    'text-hcomplaint': ticketType == 3
                  }"
                >
                  {{ ticketType.alias }}
                </p>
                <!-- <p class="text-hsub text-sm leading-none">{{ ticketType.sub }}</p> -->
              </div>
            </div>
            <div class="px-5 mt-2">
              <p class="h-0" style="border-bottom: 1px solid #e1e1e1"></p>
            </div>

            <!-- content -->
            <div class="py-2 px-5">
              <div class="mt-2" v-if="ticketType && ticketType.show.includes('division')">
                <div class="flex">
                  <IconDivision class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Division</p>
                </div>
                <p class="text-xl font-semibold ml-7.1">{{ division ? division.name : '-' }}</p>
              </div>
              <div class="mt-2" v-if="ticketType && ticketType.show.includes('department')">
                <div class="flex">
                  <IconDivision class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Department</p>
                </div>
                <p class="text-xl font-semibold ml-7.1">
                  {{ department ? department.name : '-' }}
                </p>
              </div>
              <div class="mt-2" v-if="ticketType && ticketType.show.includes('instrument')">
                <div class="flex">
                  <IconInstrument class="w-5.3 h-5.3 mr-2" />
                  <p class="text-sm">Instrument</p>
                </div>
                <p class="text-xl font-semibold ml-7.1">
                  {{ instrument ? instrument.type + ' - ' + instrument.serial_number : '-' }}
                </p>
              </div>

              <div class="mt-3" v-if="ticketType && ticketType.show.includes('staff')">
                <div class="flex">
                  <IconHuman class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Your name</p>
                </div>
                <p class="text-xl font-semibold ml-7.1">{{ form.staff_name }}</p>
              </div>
              <div class="mt-2" v-if="ticketType && ticketType.show.includes('phone')">
                <div class="flex">
                  <IconPhone class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Phone number</p>
                </div>
                <p class="text-xl font-semibold ml-7.1">{{ form.staff_phone_number }}</p>
              </div>

              <div class="mt-2" v-if="ticketType && ticketType.show.includes('description')">
                <div class="flex">
                  <IconNote class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Note</p>
                </div>
                <div
                  class="py-1 px-2.5 bg-hgray text-black mt-0.5 ml-7.1"
                  style="border-radius: 5px; min-height: 81px"
                >
                  {{ form.description }}
                </div>
              </div>

              <div class="mt-3" v-if="ticketType && ticketType.show.includes('picture')">
                <div class="flex">
                  <IconImage class="mr-2 w-5.3 h-5.3" />
                  <p class="text-sm">Picture</p>
                </div>
                <img :src="form.img" class="mt-1 ml-7.1 rounded-md" style="max-height: 112px" />
              </div>

              <div
                style="width: 100%; height: 14px; border-bottom: 4px solid #bfbfbf"
                class="text-center mt-5 mb-6"
              >
                <span class="bg-white text-sm font-bold" style="padding: 0 7px"> Priority </span>
              </div>

              <div>
                <div
                  class="px-2 pt-1.5"
                  style="border-radius: 8px; height: 39px"
                  :class="{
                    'bg-hlow text-htextlow': form.priority == 'low',
                    'bg-hmedium text-htextmedium': form.priority == 'medium',
                    'bg-hhigh text-htexthigh': form.priority == 'high'
                  }"
                >
                  <p class="text-center font-semibold capitalize">{{ form.priority }}</p>
                </div>
              </div>

              <div class="mt-5">
                <div class="flex flex-row justify-end">
                  <div class="mr-2">
                    <button
                      class="btn btn-ghost capitalize h-9.3 hover:border-transparent"
                      @click="MODAL_CONFIRM_NEW_TICKET(false)"
                    >
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn-primary hover:bg-hblue h-9.3 mx-auto capitalize border-0"
                      :class="loading == true ? 'loading' : ''"
                      style="min-width: 162px"
                      @click="confirm"
                    >
                      <span class="text-sm capitalize">Confirm</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import IconDivision from '@/assets/icons/icon-division.svg?inline'
import IconInstrument from '@/assets/icons/icon-instrument.svg?inline'
import IconPhone from '@/assets/icons/icon-phone.svg?inline'
import IconNote from '@/assets/icons/icon-note.svg?inline'
import IconHuman from '@/assets/icons/icon-profile.svg?inline'
import IconImage from '@/assets/icons/icon-image.svg?inline'

import SuccessLg from '@/assets/images/success-lg.png'
import NoImage from '@/assets/images/no-image-xs.png'

import TicketTechnical from '@/assets/icons/icon-technicalComplaint.svg?inline'
import TicketRequest from '@/assets/icons/icon-Request.svg?inline'
import TicketNTComplaint from '@/assets/icons/icon-ncComplaint.svg?inline'
import TicketNTRequest from '@/assets/icons/icon-ntcRequest.svg?inline'

export default {
  name: 'confirm-ticket-technical',
  components: {
    TicketTechnical,
    TicketRequest,
    TicketNTComplaint,
    TicketNTRequest,
    IconInstrument,
    IconPhone,
    IconDivision,
    IconNote,
    IconHuman,
    IconImage
  },
  data() {
    return {
      NoImage,
      SuccessLg,
      loading: false
    }
  },
  computed: {
    ...mapGetters('ticket', [
      'findDivision',
      'findInstrument',
      'findTicketType',
      'findDepartment',
      'ticketTypes'
    ]),
    ...mapState({
      form: state => state.ticket.form
    }),
    instrument() {
      return this.findInstrument(this.form.instrument_id)
    },
    division() {
      return this.findDivision(this.form.division_id)
    },
    department() {
      return this.findDepartment(this.form.department_id)
    },
    ticketType() {
      let data = this.ticketTypes
      let temp = data.menus.find(obj => {
        return obj.id == this.form.ticket_type_id
      })

      return temp
    },
    isOpen: {
      get: function () {
        return this.$store.state.modal.confirmNewTicket
      },
      set: function (value) {
        this.MODAL_CONFIRM_NEW_TICKET(value)
      }
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_CONFIRM_NEW_TICKET', 'MODAL_CREATED_TICKET']),
    ...mapActions('ticket', ['CREATE_TICKET']),
    close() {
      this.MODAL_CONFIRM_NEW_TICKET(false)
    },
    async confirm() {
      this.loading = true

      let res = await this.CREATE_TICKET(this.form)

      this.loading = false

      if (res) {
        this.MODAL_CONFIRM_NEW_TICKET(false)
        this.MODAL_CREATED_TICKET(true)
      }
    }
  }
}
</script>
