<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-sm"
    >
      <div class="relative bg-white sm:rounded-28px pt-7 pb-9">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="w-full">
            <div class="flex justify-between items-center px-11">
              <div class="font-semibold text-htext">
                {{ isOpen ? isOpen.type + ' - ' + isOpen.serial_number : '-' }}
              </div>
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <div class="grid gap-5 mx-auto mt-6" style="max-width: 295px">
              <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="openTicket(isOpen.id)"
              >
                <div class="flex">
                  <IconTicket class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">Open ticket</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
              <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toTickets(isOpen.id)"
              >
                <div class="flex">
                  <IconTicket class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">my ticket</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
              <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toCertificate(isOpen.id)"
              >
                <div class="flex">
                  <IconCertificate class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">Instrument Certificate</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
              <div
                class="py-4 px-5 hb-instrument flex justify-between relative cursor-pointer"
                @click="toCertificateTraining(isOpen['instrument type id'])"
              >
                <div class="flex">
                  <IconCertificate class="mr-2 h-6 w-7.2" />
                  <span class="text-htext font-bold">Training Certificate</span>
                </div>
                <IconArrow
                  class="absolute right-0 inset-y-auto pt-1 mr-1 h-5 w-5 stroke-current text-hblue1 justify-self-end"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'
import IconCertificate from '@/assets/icons/icon-certificate.svg?inline'
import IconArrow from '@/assets/icons/icon-arrow-nav.svg?inline'
import IconTicket from '@/assets/icons/icon-ticket.svg?inline'

export default {
  name: 'modal-instrument',
  components: {
    IconCertificate,
    IconArrow,
    IconTicket
  },
  data() {
    return {
      SvgClear,
      divisions: []
    }
  },

  methods: {
    ...mapActions('modal', ['MODAL_INSTRUMENT']),
    close() {
      this.MODAL_INSTRUMENT(false)
    },
    toTickets(val) {
      this.close()
      this.$router.push({
        name: 'ticket.index',
        query: { instrument_id: val, offset: 0, limit: 9, ticket_type: 0, t: new Date().getTime() }
      })
    },
    async openTicket(val) {
      this.close()
      this.$router.push({
        name: 'ticket.open.qr',
        params: { id: 2 },
        query: { instrument_id: val }
      })
    },
    toCertificate(val) {
      this.close()
      this.$router.push({
        name: 'certificates',
        query: { limit: 4, offset: 0, instrument_id: val }
      })
    },
    toCertificateTraining(val) {
      this.close()
      this.$router.push({
        name: 'certificates.training',
        query: { instrument: val, page: 1 }
      })
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.instrument
      },
      set: function (value) {
        this.MODAL_INSTRUMENT(value)
      }
    }
  }
}
</script>
