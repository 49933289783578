export default function () {
  return {
    user: {},
    loggedIn: false,
    fcm: null,
    plannings: [],
    switchs: [],
    instruments: [],
    haveInstrument: false
  }
}
