export const listDesignation = state => {
  let data = state.designations
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.label = d.name
      temp.push(d)
    })
  }

  return temp
}
