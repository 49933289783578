<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-lg"
    >
      <div class="relative bg-white sm:rounded-lg">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <div class="flex justify-end items-center px-5 py-4">
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <!--  -->
            <div class="text-center px-5">
              <IconCheck class="h-31.5 w-31.5 mx-auto z-10 relative" />

              <div class="relative font-normal text-lg text-black mt-4 z-10">
                {{ ticket.number }}
              </div>
              <div class="relative font-semibold text-2xl z-10 text-hwarning1">
                Ticket created successfully
              </div>
            </div>
            <div class="flex justify-center pt-5 pb-12 px-5">
              <button
                class="btn btn-ghost rounded-full capitalize z-10 sm:justify-self-end text-htealdark hover:border-transparent"
                style="width: 180px"
                @click="close"
              >
                Back to home
              </button>
              <button
                class="btn btn-secondary hover:bg-htealdark rounded-full capitalize z-10 sm:justify-self-start border-0"
                style="width: 180px"
                @click="toDetail"
              >
                Detail Ticket
              </button>
            </div>
            <!-- <div class="relative">
              <div class="wave"></div>
              <div class="wave"></div>
            </div> -->
          </div>
        </div>
        <!-- <div class="wave"></div>
        <div class="wave"></div> -->

        <div class="wave-1"><span>wave</span></div>
        <div class="wave-2"><span>wave</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'
import IconCheck from '@/assets/icons/icon-checkRound.svg?inline'

export default {
  name: 'ticket-created',
  components: {
    IconCheck
  },
  data() {
    return {
      SvgClear
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_CREATED_TICKET']),
    close() {
      this.MODAL_CREATED_TICKET(false)
      this.$router.replace({ name: 'home' })
    },
    toDetail() {
      this.MODAL_CREATED_TICKET(false)
      let val = this.ticket
      val.act = 'myticket'

      let removeSpacing = val.number ? val.number.replaceAll(/\s+/g, '-') : ''
      let removeSlash = removeSpacing.replaceAll('/', '')
      this.$router.replace({ name: 'ticket.detail', params: { slug: removeSlash, id: val.id } })
    }
  },
  computed: {
    ...mapState({
      ticket: state => state.ticket.ticket
    }),
    isOpen: {
      get: function () {
        return this.$store.state.modal.createdTicket
      },
      set: function (value) {
        this.MODAL_CREATED_TICKET(value)
      }
    }
  }
}
</script>

<style>
.wave-1 {
  color: transparent;
  text-decoration: none;
  width: 100%;
  line-height: 230px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  transition: all 1s;
}

.wave-1:before {
  content: '';
  position: absolute;
  width: 800px;
  height: 800px;
  border-radius: 360px;
  background-color: rgba(192, 230, 255, 0.44);
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  animation: wave 24s infinite linear;
  transition: all 1s;
}
.wave-2 {
  color: transparent;
  text-decoration: none;
  width: 100%;
  line-height: 240px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  transition: all 1s;
}

.wave-2:before {
  content: '';
  position: absolute;
  width: 750px;
  height: 750px;
  border-radius: 340px;
  background-color: rgba(61, 178, 255, 0.44);
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  animation: wave 19s infinite linear;
  transition: all 1s;
}

/* .wave-center:hover:before {
  top: 15px;
} */

@keyframes wave {
  0% {
    transform: translate(-50%) rotate(-180deg);
  }
  /* 50% {
    transform: translate(-50%) rotate(90deg);
  } */
  100% {
    transform: translate(-50%) rotate(360deg);
  }
}
</style>
