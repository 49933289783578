export const ME = (state, data) => {
  state.user = data
  state.loggedIn = true
}

export const LOGOUT = state => {
  state.user = {}
  state.loggedIn = false
}

export const FCM_TOKEN = (state, val) => {
  state.fcm = val
}

export const GET_PLANNINGS = (state, val) => {
  state.plannings = val
}

export const GET_SWITCH_CUSTOMERS = (state, val) => {
  state.switchs = val
}

export const CHECK_INSTRUMENTS = (state, val) => {
  state.haveInstrument = val.haveInstrument
  state.instruments = val.instruments
}
