import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from './auth'
import modal from './modal'
import notif from './notif'
import ticket from './ticket'
import banner from './banner'
import instrument from './instrument'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'web',
  storage: window.localStorage,
  reducer: state => ({
    auth: state.auth,
    banner: state.banner,
    ticket: state.ticket
  })
})

const store = new Vuex.Store({
  modules: {
    auth,
    modal,
    notif,
    ticket,
    banner,
    instrument
  },
  plugins: [vuexLocalStorage.plugin]
})

export default store
