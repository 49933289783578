<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-lg"
    >
      <div class="bg-white flex flex-col" style="border-radius: 10px">
        <div class="max-h-full w-full px-8 py-6">
          <div class="flex justify-between items-center mb-6">
            <div class="relative">
              <div
                class="font-Roboto text-32px text-htext font-bold pr-7"
                v-if="isOpen == 'technical'"
              >
                Technical Ticket&nbsp;&nbsp;
              </div>
              <div class="font-Roboto text-32px text-htext font-bold pr-7" v-else>
                Non Technical Ticket&nbsp;&nbsp;
              </div>
              <div class="border-b border-hgray2 mt-2"></div>
            </div>
            <img :src="SvgClear" class="cursor-pointer" @click="close" />
          </div>

          <div v-if="isOpen == 'technical'">
            <div class="flex flex-col">
              <div class="text-htext font-semibold">Technical Complaint</div>
              <div class="text-sm mt-1">
                Berisi laporan kerusakan/error pada alat/hasil QC/sample tidak sesuai/printer/UPS,
                hasil yang berkaitan dengan Eng./Apl./IT
              </div>
              <div class="text-sm mt-3">
                Contoh: -0.04 Mpa error, Water Leak Detected, UPS error, Printer error, QC Trend
                High/Low, Patient Result trend High/Low, Hasil tidak terkirim ke LIS/HCLab, HCLab
                offline
              </div>
            </div>

            <div class="flex flex-col mt-7 mb-2">
              <div class="text-htext font-semibold">Technical Request</div>
              <div class="text-sm mt-1">Berisi permintaan yang diajukan ke Eng./Apl./IT</div>
              <div class="text-sm mt-3">
                Contoh: Permintaan metode PME/INAEQAS/PME ILKI, permintaan assay sheet/nilai
                kontrol/kalibrator, permintaan MSDS, permintaan nilai normal, permintaan jadwal
                maitenance/jadwal kalibrasi dll, permintaan visit oleh Apliksi/Eng/IT
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col">
              <div class="text-htext font-semibold">Non Technical Complaint</div>
              <div class="text-sm mt-1">
                Berisi keluhan yang tidak berhubungan dengan hal teknis – dapat ditujukan ke
                departemen CCIT, Sales, SCM, Finance, Marketing
              </div>
              <div class="text-sm mt-3">
                Contoh: sudah mengajukan PO reagent/QC tapi barang belum datang, alat sudah diinstal
                tapi box belum diambil, teknisi/aplikasi tidak tuntas pekerjaannya, staf Sysmex
                kurang sopan dll
              </div>
            </div>

            <div class="flex flex-col mt-7 mb-2">
              <div class="text-htext font-semibold">Non Technical Request</div>
              <div class="text-sm mt-1">
                Berisi permintaan yang tidak berhubungan dengan hal teknis – dapat ditujukan ke
                departemen CCIT, Sales, SCM, Finance, Marketing
              </div>
              <div class="text-sm mt-3">
                Contoh: Permintaan atlas hematology, Permintaan visit ke departement Sales,
                permintaan sertifikat event.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgClear from '@/assets/icons/icon-close.svg'

import WaveBack from '@/assets/images/wave-back.png'
import WaveFront from '@/assets/images/wave-front.png'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'open-info',
  data() {
    return {
      SvgClear,
      WaveBack,
      WaveFront,
      loading: false
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_INFO']),
    close() {
      this.MODAL_INFO(false)
      this.loading = false
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.info
      },
      set: function (value) {
        this.MODAL_INFO(value)
      }
    },
    ...mapState({
      action: state => state.ticket.action,
      ticket: state => state.ticket.ticket,
      attachments: state => state.ticket.attachment_deletes
    })
  }
}
</script>
