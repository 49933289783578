<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="MODAL_VIDEO(false)"></div>

    <div class="absolute max-h-full w-full bottom-0 sm:inset-y-auto max-w-2xl">
      <div class="relative bg-white overflow-hidden sm:rounded-lg">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full" style="height: 360px">
            <!-- <div class="flex justify-end pt-3.5">
              <img :src="SvgClear" class="cursor-pointer pb-3.5 pr-4" @click="MODAL_VIDEO(false)" />
            </div> -->

            <youtube :video-id="isOpen" ref="youtube" resize></youtube>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'

export default {
  name: 'ticket-review',
  data() {
    return {
      SvgClear
    }
  },

  methods: {
    ...mapActions('modal', ['MODAL_VIDEO'])
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.modalVideo
      },
      set: function (value) {
        this.MODAL_VIDEO(value)
      }
    }
  }
}
</script>
