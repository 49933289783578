<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div class="absolute max-h-full w-full bottom-0 sm:inset-y-auto max-w-lg">
      <div class="relative bg-white overflow-hidden sm:rounded-lg">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full" style="height: 320px">
            <div class="flex justify-end items-center px-5 pt-5">
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <div class="text-5xl text-htealdark font-semibold mt-13 z-10 text-center relative">
              Thank you!
            </div>

            <div
              class="text-xl text-white mt-2.5 z-10 px-3 text-center relative"
              style="text-shadow: 1px 1px 2px #137290, 0 0 1em blue, 0 0 0.2em #137290"
            >
              Your feedback is very helpfull to us
            </div>
          </div>
        </div>

        <div class="waver-1"><span>wave</span></div>
        <div class="waver-2"><span>wave</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'

export default {
  name: 'ticket-review',
  data() {
    return {
      SvgClear
    }
  },

  methods: {
    ...mapActions('modal', ['MODAL_THX']),
    close() {
      this.MODAL_THX(false)

      if (this.ticket && this.ticket.act == 'myticket') this.$router.go(-1)
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.thx
      },
      set: function (value) {
        this.MODAL_THX(value)
      }
    },
    ...mapState({
      ticket: state => state.ticket.ticket
    })
  }
}
</script>

<style>
.waver-1 {
  color: transparent;
  text-decoration: none;
  width: 100%;
  line-height: 220px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  transition: all 1s;
}

.waver-1:before {
  content: '';
  position: absolute;
  width: 770px;
  height: 680px;
  border-radius: 340px;
  background-color: rgba(192, 230, 255, 0.44);
  top: 58px;
  left: 50%;
  transform: translate(-50%);
  animation: waver 24s infinite linear;
  transition: all 1s;
}
.waver-2 {
  color: transparent;
  text-decoration: none;
  width: 100%;
  line-height: 220px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  transition: all 1s;
}

.waver-2:before {
  content: '';
  position: absolute;
  width: 800px;
  height: 700px;
  border-radius: 350px;
  background-color: rgba(61, 178, 255, 0.44);
  top: 64px;
  left: 50%;
  transform: translate(-50%);
  animation: waver 19s infinite linear;
  transition: all 1s;
}

@keyframes waver {
  0% {
    transform: translate(-50%) rotate(-180deg);
  }

  100% {
    transform: translate(-50%) rotate(360deg);
  }
}
</style>
