// export const MODAL_CONFIRM_TECHNICAL = (state, val) => {
//   state.confirmNewTechnical = val
// }

// export const MODAL_CONFIRM_REQUEST = (state, val) => {
//   state.confirmNewRequest = val
// }

export const MODAL_CONFIRM = (state, val) => {
  state.confirm = val
}

export const MODAL_THX = (state, val) => {
  state.thx = val
}

export const MODAL_REVIEW_TICKET = (state, val) => {
  state.reviewTicket = val
}

export const MODAL_CREATED_TICKET = (state, val) => {
  state.createdTicket = val
}

export const MODAL_CONFIRM_NEW_TICKET = (state, val) => {
  state.confirmNewTicket = val
}

export const DATA_TICKET = (state, val) => {
  state.ticket = val
}

export const MODAL_UPLOAD_ATTACHMENT = (state, val) => {
  state.uploadAttachment = val
}

export const MODAL_DETAIL_ATTACHMENT = (state, val) => {
  state.detailAttachment = val
}

export const MODAL_ERROR = (state, val) => {
  state.modalError = val
}

export const MODAL_TICKET_TYPE = (state, val) => {
  state.ticket_type = val
}

export const MODAL_INSTRUMENT = (state, val) => {
  state.instrument = val
}

export const MODAL_CERTIFICATE = (state, val) => {
  state.certificate = val
}

export const MODAL_VIDEO = (state, val) => {
  state.modalVideo = val
}

export const MODAL_INFO = (state, val) => {
  state.info = val
}

export const MODAL_CAF_DETAIL = (state, val) => {
  state.caf = val
}

export const MODAL_BOT = (state, val) => {
  state.bot = val
}

export const MODAL_CAF_POP = (state, val) => {
  state.cafPop = val
}
