var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpen)?_c('div',{staticClass:"fixed w-full h-full top-0 left-0 flex items-center justify-center z-30"},[_c('div',{staticClass:"absolute w-full h-full bg-gray-900 opacity-50",on:{"click":_vm.close}}),_c('div',{staticClass:"absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-md"},[_c('div',{staticClass:"bg-hgray9 flex flex-col",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"max-h-full w-full px-4 pt-4 pb-6 bg-hwarning3 rounded-tl-10px rounded-tr-10px"},[_c('div',{staticClass:"flex items-center space-x-3.5"},[_c('div',{},[_c('IconWarning',{staticClass:"pt-1"})],1),_vm._m(0)])]),(_vm.carouselItems.length)?_c('div',{staticClass:"flex flex-col p-8"},[_c('div',{staticClass:"flex space-x-4 justify-between items-center mb-2 px-2"},[_c('div',{staticClass:"text-hservice font-medium font-Poppins"},[_vm._v("Customer Acknowledgement Form")]),_c('div',{staticClass:"text-hgray5 text-sm"},[_vm._v(" ("+_vm._s(_vm.currentIndex + 1)+"/"+_vm._s(_vm.carouselItems.length)+") ")])]),_c('div',{staticClass:"caf"},[_c('agile',{attrs:{"options":_vm.carouselOptions},on:{"after-change":function (e) { return (_vm.currentIndex = e.currentSlide); }}},_vm._l((_vm.carouselItems),function(item,index){return _c('div',{key:index,staticClass:"slide relative pl-10 pr-4 pt-7",staticStyle:{"height":"10rem"}},[_c('div',{staticClass:"caf-box-shadow absolute top-2 bottom-2 right-2 left-2"}),_c('BgCaf',{staticClass:"absolute h-5/6 right-3 top-2"}),_c('div',{staticClass:"absolute left-2 w-2 bottom-2 top-2 bg-hservice rounded-tl-lg rounded-bl-lg"}),_c('div',{staticClass:"flex flex-col font-Poppins relative z-2 pb-3"},[_c('div',{staticClass:"flex space-x-3 items-center"},[_c('div',{staticClass:"px-2 py-1 text-xs font-Roboto font-semibold leading-none",class:{
                      'text-white bg-hred1 rounded-lg': item.status === 'Created',
                      'text-white bg-hred2 rounded-lg': item.status === 'Read',
                      'text-white bg-hblue rounded-lg': item.status === 'Submited',
                      'text-white bg-horange2 rounded-lg': item.status === 'Revision',
                      'text-black bg-hgray12 rounded-lg': item.status === 'Rejected',
                      'text-white bg-hgray12 rounded-lg': item.status === 'Approved'
                    }},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('div',{staticClass:"text-13px text-hgray8 font-Roboto line-clamp-1"},[_vm._v(" "+_vm._s(item.revision)+" ")])]),_c('div',{staticClass:"text-hservice font-medium mt-2 line-clamp-2"},[_vm._v(_vm._s(item.number))]),_c('div',{staticClass:"text-hgray8 text-sm mt-1 line-clamp-2"},[_vm._v(" "+_vm._s(item.caf_letter ? item.caf_letter.product_name : '-')+" ")]),_c('div',{staticClass:"text-hgray8 text-sm"},[_vm._v(_vm._s(item.caf_type))])])],1)}),0)],1)]):_vm._e(),_c('div',{staticClass:"max-h-full w-full px-8 pt-6 pb-8 bg-hgray9 rounded-bl-10px rounded-br-10px"},[_c('div',{staticClass:"space-x-5 flex items-center justify-center"},[_c('button',{staticClass:"btn btn-ghost capitalize h-9.3 hover:bg-transparent border-hblue text-htextlight text-xs",on:{"click":_vm.close}},[_vm._v(" Remind me later ")]),_c('button',{staticClass:"btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center space-x-3 px-5",on:{"click":_vm.redirectToCafPage}},[_c('span',{staticClass:"text-sm"},[_vm._v("See detail")]),_c('IconArrow',{staticClass:"h-4 w-5"})],1)])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-sm font-semibold text-hwarning4"},[_vm._v("Attention")]),_c('div',{staticClass:"text-xs text-hgray7 mt-1"},[_vm._v(" Please read notification below and fill each coloum and then submit your response. ")])])}]

export { render, staticRenderFns }