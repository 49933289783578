<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-md"
    >
      <div class="bg-hgray9 flex flex-col" style="border-radius: 10px">
        <div class="max-h-full w-full px-4 pt-4 pb-6 bg-hwarning3 rounded-tl-10px rounded-tr-10px">
          <div class="flex items-center space-x-3.5">
            <div class="">
              <IconWarning class="pt-1" />
            </div>
            <div class="flex flex-col">
              <div class="text-sm font-semibold text-hwarning4">Attention</div>
              <div class="text-xs text-hgray7 mt-1">
                Please read notification below and fill each coloum and then submit your response.
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col p-8" v-if="carouselItems.length">
          <div class="flex space-x-4 justify-between items-center mb-2 px-2">
            <div class="text-hservice font-medium font-Poppins">Customer Acknowledgement Form</div>
            <div class="text-hgray5 text-sm">
              ({{ currentIndex + 1 }}/{{ carouselItems.length }})
            </div>
          </div>

          <!-- <div class="carousel carousel-center space-x-4 items-center" @scroll="onScroll">
            <div
              v-for="(item, index) in carouselItems"
              :key="index"
              style="height: 9.1rem"
              class="relative flex flex-col font-Poppins caf-box-shadow pl-9 pr-4 pb-2 pt-6 carousel-item w-318 my-2"
            >
              <BgCaf class="absolute h-5/6 right-0" />
              <div
                class="absolute left-0 w-3 bottom-0 top-0 bg-hservice rounded-tl-lg rounded-bl-lg"
              ></div>
              <div class="absolute right-1.5 top-1.5 text-11px font-medium text-hgray8">
                #{{ index + 1 }}
              </div>
              <div class="text-hgray8 text-sm">
                <span
                  :class="{
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue1 rounded-lg':
                      item.status === 'Created',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue3 rounded-lgbg-white':
                      item.status === 'Read',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue4 rounded-lg':
                      item.status === 'Submited',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-horange rounded-lg':
                      item.status === 'Revision',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-red-500 rounded-lg':
                      item.status === 'Rejected',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hgreen rounded-lg':
                      item.status === 'Approved'
                  }"
                  >{{ item.status }}</span
                >
                <div class="text-xs text-hgray10">{{ item.revision }}</div>
              </div>
              <div class="text-hservice font-medium mt-2">{{ item.number }}</div>
              <div class="text-hgray8 text-sm mt-1">
                {{ item.caf_letter ? item.caf_letter.product_name : '-' }}
              </div>
              <div class="text-hgray8 text-sm">{{ item.caf_type }}</div>
            </div>
          </div> -->

          <div class="caf">
            <agile :options="carouselOptions" @after-change="e => (currentIndex = e.currentSlide)">
              <div
                v-for="(item, index) in carouselItems"
                :key="index"
                style="height: 10rem"
                class="slide relative pl-10 pr-4 pt-7"
              >
                <div class="caf-box-shadow absolute top-2 bottom-2 right-2 left-2"></div>
                <BgCaf class="absolute h-5/6 right-3 top-2" />
                <div
                  class="absolute left-2 w-2 bottom-2 top-2 bg-hservice rounded-tl-lg rounded-bl-lg"
                ></div>
                <div class="flex flex-col font-Poppins relative z-2 pb-3">
                  <div class="flex space-x-3 items-center">
                    <div
                      class="px-2 py-1 text-xs font-Roboto font-semibold leading-none"
                      :class="{
                        'text-white bg-hred1 rounded-lg': item.status === 'Created',
                        'text-white bg-hred2 rounded-lg': item.status === 'Read',
                        'text-white bg-hblue rounded-lg': item.status === 'Submited',
                        'text-white bg-horange2 rounded-lg': item.status === 'Revision',
                        'text-black bg-hgray12 rounded-lg': item.status === 'Rejected',
                        'text-white bg-hgray12 rounded-lg': item.status === 'Approved'
                      }"
                    >
                      {{ item.status }}
                    </div>
                    <div class="text-13px text-hgray8 font-Roboto line-clamp-1">
                      {{ item.revision }}
                    </div>
                  </div>
                  <div class="text-hservice font-medium mt-2 line-clamp-2">{{ item.number }}</div>
                  <div class="text-hgray8 text-sm mt-1 line-clamp-2">
                    {{ item.caf_letter ? item.caf_letter.product_name : '-' }}
                  </div>
                  <div class="text-hgray8 text-sm">{{ item.caf_type }}</div>
                </div>
              </div>
            </agile>
          </div>
        </div>

        <div class="max-h-full w-full px-8 pt-6 pb-8 bg-hgray9 rounded-bl-10px rounded-br-10px">
          <div class="space-x-5 flex items-center justify-center">
            <button
              @click="close"
              class="btn btn-ghost capitalize h-9.3 hover:bg-transparent border-hblue text-htextlight text-xs"
            >
              Remind me later
            </button>
            <button
              class="btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center space-x-3 px-5"
              @click="redirectToCafPage"
            >
              <span class="text-sm">See detail</span>
              <IconArrow class="h-4 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgClear from '@/assets/icons/icon-close.svg'
import IconWarning from '@/assets/icons/warning.svg?inline'
import BgCaf from '@/assets/icons/bg-caf.svg?inline'
import IconArrow from '@/assets/icons/arrow.svg?inline'
import { mapActions, mapState } from 'vuex'
// import { VueAgile } from 'vue-agile'

export default {
  name: 'caf-pop',
  components: {
    // agile: VueAgile,
    IconWarning,
    IconArrow,
    BgCaf
  },
  data() {
    return {
      SvgClear,
      loading: false,
      currentIndex: 0,
      carouselOptions: {
        slidesToShow: 1,
        centerMode: true,
        initialSlide: 0,
        autoplay: true,
        speed: 3000
      }
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_CAF_POP', 'MODAL_CAF_DETAIL']),
    ...mapActions('instrument', ['GET_CAF', 'DETAIL_CAF']),
    close() {
      this.MODAL_CAF_POP(false)
      this.loading = false
    },
    handleClick(b) {
      this.close()
      this.DETAIL_CAF(b.id)
    },
    redirectToCafPage() {
      this.$router.push({ name: 'caf', query: { page: 1, status: 'Created,Revision' } })
      this.MODAL_CAF_POP(false)
    },
    onScroll(e) {
      console.log(e)
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.cafPop
      },
      set: function (value) {
        this.MODAL_CAF_POP(value)
      }
    },
    ...mapState({
      carouselItems: state => state.instrument.carouselItems
    })
  }
}
</script>
