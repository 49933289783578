export const listInstrument = state => {
  let data = state.user && state.user.instruments ? state.user.instruments.data : null
  let temp = []

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      if (d.manufacture == 'Sysmex') {
        temp.push({
          title: d.type,
          serialNumber: d.serial_number,
          manufacture: d.manufacture,
          category: d.category,
          contract: d.contract_type,
          is_active: d.is_active,
          is_install: d.is_install,
          type: 'device',
          source: ''
        })
      }
    })
  }

  return temp
}
