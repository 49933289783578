export default function () {
  return {
    broadcast: {
      data: [],
      count: 0,
      detail: {}
    },
    notifications: [],
    points: [],
    point: null
  }
}
