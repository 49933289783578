<template>
  <div
    class="font-Poppins fixed w-full h-full top-0 left-0 flex items-center justify-center z-30"
    v-if="caf"
  >
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden max-w-md">
      <div class="bg-white flex flex-col relative rounded-10px">
        <div
          class="absolute top-0 z-10 bg-white max-h-full w-full p-4 rounded-tl-10px rounded-tr-10px flex"
        >
          <div class="flex-none flex items-center space-x-2.5 cursor-pointer" @click="close">
            <IconBack class="h-6 w-6" />
            <span class="text-xl font-medium">CAF Form</span>
          </div>
        </div>

        <div class="max-h-screen pt-13 pb-24 overflow-y-auto">
          <div class="w-full flex flex-col">
            <div
              class="flex flex-col relative pr-3 py-3 pl-6 bg-white rounded-md m-4"
              style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            >
              <BgCaf class="absolute h-5/6 right-2 top-0" />
              <div
                class="absolute left-0 bottom-0 top-0 w-2.5 bg-hservice rounded-tl-md rounded-bl-md"
              ></div>
              <div class="font-medium text-hservice">
                {{ caf.number }}
              </div>
              <div class="text-sm text-hgray8 mt-1">
                {{ caf.caf_letter ? caf.caf_letter.product_name : '-' }}
              </div>
              <div class="text-sm text-hgray8 mt-1">
                {{ caf.caf_type }}
              </div>
              <div
                class="text-xs w-min mt-1"
                :class="{
                  'px-2 py-1 font-semibold text-white bg-hred1 rounded-full':
                    caf.status === 'Created',
                  'px-2 py-1 font-semibold text-white bg-hred2 rounded-full': caf.status === 'Read',
                  'px-2 py-1 font-semibold text-white bg-hblue rounded-full':
                    caf.status === 'Submitted',
                  'px-2 py-1 font-semibold text-white bg-horange2 rounded-full':
                    caf.status === 'Revision',
                  'px-2 py-1 font-semibold text-black bg-hgray12 rounded-full':
                    caf.status === 'Rejected',
                  'px-2 py-1 font-semibold text-white bg-hgray12 rounded-full':
                    caf.status === 'Approved'
                }"
              >
                {{ caf.status }}
              </div>

              <div class="text-sm text-hgray8 mt-1" v-if="caf.revision">
                <div class="text-xs font-light">Note :</div>
                <div class="border border-solid border-gray-300 p-4" style="border-radius: 10px">
                  {{ caf.revision }}
                </div>
              </div>
            </div>

            <div v-if="caf.caf_choice_questions" class="my-4 px-4">
              <div
                v-for="question in caf.caf_choice_questions"
                :key="question.id"
                class="radio-group"
              >
                <div v-if="question.is_selected" class="flex items-center space-x-2">
                  <input
                    type="radio"
                    checked="checked"
                    value=""
                    readonly
                    :id="'question_' + question.id"
                    class="radio-input text-sm"
                  />
                  <label :for="'question_' + question.id" class="radio-label pt-0.5">
                    {{ question.question }}
                  </label>
                </div>
              </div>
            </div>

            <div v-if="caf.caf_answer_questions" class="px-4">
              <div
                v-for="(x, index) in caf.caf_answer_questions"
                :key="x.id"
                class="answer-question-group"
                style="margin-bottom: 20px"
              >
                <label
                  :for="'answer_question_' + x.id"
                  class="answer-question-label text-sm"
                  style="margin-bottom: 5px"
                  >{{ index + 1 }}. {{ x.question }}</label
                >

                <input
                  type="text"
                  :id="'answer_question_' + x.id"
                  v-model="x.answer"
                  disabled
                  placeholder="Company name here..."
                  class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
                />
              </div>
            </div>

            <!-- <div
              class="mt-4 rounded px-3 py-1 bg-hgray flex flex-col"
              v-for="d in caf.instruments"
              :key="d.id"
            >
              <div class="text-hgray8 text-sm font-medium">{{ d.type }}</div>
              <div class="text-hgray8 text-xs font-light">SN: {{ d.serial_number }}</div>
            </div> -->

            <div v-if="caf.designation" class="flex space-x-2 items-center mt-5 mb-1 px-4">
              <IconDate class="h-5 w-5 mb-0.5" />
              <span class="font-semibold text-hblue">
                {{ caf.designation.date | moment('dddd, DD MMMM YYYY') }}
              </span>
            </div>
          </div>

          <div class="p-4 flex flex-col space-y-4" v-if="caf.designation">
            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Company name</span>
              <input
                type="text"
                v-model="caf.designation.caf_customer_name"
                disabled
                placeholder="Company name here..."
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
              />
            </div>

            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Address</span>
              <textarea
                v-model="caf.designation.caf_customer_address"
                disabled
                placeholder="Address here..."
                class="textarea textarea-bordered text-sm transition rounded-lg w-full py-2 px-3 focus:ring-transparent focus:ring-0 h-20"
              ></textarea>
            </div>

            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Name Of Staff</span>
              <input
                type="text"
                v-model="caf.designation.client_name"
                disabled
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
              />
            </div>

            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Designation</span>
              <input
                type="text"
                v-model="des"
                disabled
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
              />
            </div>
          </div>
        </div>

        <div
          class="absolute bottom-0 max-h-full w-full px-4 pt-3.5 pb-5 bg-hgray9 rounded-bl-10px rounded-br-10px"
        >
          <div class="flex flex-col">
            <button
              @click="openDoc"
              class="bg-white text-hservice hover:text-white hover:bg-hblue h-9.3 border border-hblue text-center px-5 rounded-lg"
            >
              <span class="text-sm font-medium">Download document</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IconBack from '@/assets/icons/icon-back.svg?inline'
import IconDate from '@/assets/icons/icon-date.svg?inline'
import CafDone from '@/assets/images/caf-done.png'
import BgCaf from '@/assets/icons/bg-caf.svg?inline'

export default {
  name: 'caf-detail',
  components: {
    IconBack,
    IconDate,
    BgCaf
  },
  data() {
    return {
      CafDone,
      des: ''
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_CAF_DETAIL']),
    close() {
      this.MODAL_CAF_DETAIL(false)
    },
    openDoc() {
      window.open(this.caf.file, '_blank', 'noreferrer')
    }
  },
  computed: {
    caf: function () {
      return this.$store.state.modal.caf
    }
  },
  watch: {
    caf: {
      deep: true,
      immediate: true,
      handler(c) {
        if (c && c.designation)
          this.des = c.designation.designation_text
            ? c.designation.designation_text
            : c.designation.designation
      }
    }
  }
}
</script>
