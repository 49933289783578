export default function () {
  return {
    tickets: [],
    ticket_types: [],
    ticket: {
      number: ''
    },
    ticketStatuses: [],
    ticketTime: null,
    service_reports: [],
    form: {
      instrument_id: null,
      description: '',
      staff_name: '',
      staff_phone_number: '',
      division_id: null,
      priority: 'low',
      ticket_type_id: null,
      request_id: null,
      department_id: null
    },
    divisions: [],
    request_types: [],
    instruments: [],
    departments: [],
    count: [],
    pre_open: null,
    action: null,
    attachments: [],
    attachment: {},
    attachment_types: [],
    attachment_deletes: [],
    chats: [],
    statuses: [
      {
        id: 'new',
        label: 'New'
      },
      {
        id: 'confirmed',
        label: 'Confirmed'
      },
      {
        id: 'started',
        label: 'Started'
      },
      {
        id: 'restarted',
        label: 'Restarted'
      },
      {
        id: 'held',
        label: 'Hold'
      },
      {
        id: 'done',
        label: 'Done'
      },
      {
        id: 'closed',
        label: 'Closed'
      }
    ],
    priorities: [
      {
        id: 'low',
        label: 'Low'
      },
      {
        id: 'medium',
        label: 'Medium'
      },
      {
        id: 'high',
        label: 'High'
      }
    ],
    configProgress: {
      vuescroll: {
        mode: 'native',
        detectResize: true,
        locking: true
      },
      scrollPanel: {
        scrollingX: false,
        scrollingY: true,
        verticalNativeBarPos: 'right'
      },
      bar: {
        background: '#176FA9',
        opacity: 1,
        size: '5px',
        minSize: 0.2
      }
    },
    configChat: {
      vuescroll: {
        mode: 'native',
        sizeStrategy: 'number',
        detectResize: true,
        locking: false
      },
      scrollPanel: {
        scrollingX: false,
        scrollingY: true,
        verticalNativeBarPos: 'right',
        speed: 500,
        maxHeight: 407
      },
      bar: {
        background: '#176FA9',
        opacity: 1,
        size: '5px',
        minSize: 0.2
      }
    },
    aiTyped: false,
    aiChats: [],
    aiTicket: {
      id: null,
      number: null
    }
  }
}
