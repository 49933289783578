<template>
  <div
    class="font-Poppins fixed w-full h-full top-0 left-0 flex items-center justify-center z-30"
    v-if="caf"
  >
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden"
      :class="oy.includes(modal) ? 'max-w-md' : modal == 2 ? 'max-w-' + zoom : 'max-w-sm'"
    >
      <div v-if="modal == 1" class="bg-white flex flex-col" style="border-radius: 10px">
        <div class="max-h-full w-full px-4 pt-4 pb-6 bg-hwarning3 rounded-tl-10px rounded-tr-10px">
          <div class="flex items-center space-x-3.5">
            <div class="flex-none">
              <IconWarning class="pt-1" />
            </div>
            <div class="flex flex-col">
              <div class="text-sm font-semibold text-hwarning4">Attention</div>
              <div class="text-xs text-hgray7 mt-1">
                Please read notification below and fill each coloum and then submit your response.
              </div>
            </div>
          </div>
        </div>
        <div class="max-h-full w-full px-6 pt-4 pb-6 flex flex-col">
          <div class="text-hservice text-lg font-semibold">Customer Acknowledgement Form</div>

          <div
            class="relative font-Poppins rounded-lg bg-hgray hover:bg-hblu10 pl-7 pr-4 pt-3 pb-2 cursor-pointer"
          >
            <div
              class="absolute left-0 w-3 bottom-0 top-0 bg-hservice rounded-tl-lg rounded-bl-lg"
            ></div>

            <div class="flex items-center sm:justify-between">
              <div class="text-hservice font-medium">
                {{ caf.number }}
              </div>
            </div>
            <div class="text-hgray7 text-sm mt-1">
              {{ caf.caf_letter ? caf.caf_letter.product_name : '-' }}
            </div>
            <div class="text-hgray7 text-sm">
              {{ caf.caf_type }}
            </div>
            <div class="text-hgray7 text-sm mb-4 mt-4">
              <span
                :class="{
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue1 rounded-full':
                    caf.status === 'Created',
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue3 rounded-full':
                    caf.status === 'Read',
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue4 rounded-full':
                    caf.status === 'Submitted',
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-horange rounded-full':
                    caf.status === 'Revision',
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-red-500 rounded-full':
                    caf.status === 'Rejected',
                  'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hgreen rounded-full':
                    caf.status === 'Approved'
                }"
                >{{ caf.status }}</span
              >
            </div>
          </div>
        </div>

        <div class="max-h-full w-full px-4 pt-7 pb-6 bg-hgray9 rounded-bl-10px rounded-br-10px">
          <div class="space-x-5 flex items-center justify-center">
            <button
              @click="close"
              class="btn btn-ghost capitalize h-9.3 hover:bg-transparent border-hblue text-htextlight text-xs"
            >
              Remain me later
            </button>
            <button
              @click="toPage(2)"
              class="btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center space-x-3 px-5"
            >
              <span class="text-sm">See detail</span>
              <IconArrow class="h-4 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="modal == 2 && caf.file" class="bg-white flex flex-col relative rounded-10px">
        <div
          class="absolute top-0 z-10 bg-white max-h-full w-full p-4 rounded-tl-10px rounded-tr-10px flex items-center justify-between"
        >
          <div class="flex-none flex items-center space-x-2.5 cursor-pointer" @click="close">
            <!-- @click="toPage(modal - 1)" -->
            <IconBack class="h-6 w-6" />
            <span class="text-xl">CAF Letter Detail</span>
          </div>
          <div v-if="!loadingPdf" class="flex space-x-3 items-center">
            <a :href="caf.file" target="_blank" class="p-1 hover:bg-hgray rounded">
              <IconDownload class="h-4 w-4" />
            </a>
            <treeselect
              class="h-9 hidden sm:block w-30"
              :multiple="false"
              v-model="zoom"
              :options="zoomList"
              placeholder="Zoom"
              :clearable="false"
            />
          </div>
        </div>

        <div
          class="max-h-screen w-full relative bg-hgray3 p-3 pt-18 pb-21 overflow-y-auto"
          @scroll="onScroll"
        >
          <div v-if="loadingPdf" class="flex justify-center items-center h-full">
            <img src="https://web.viewproxy.com/pdfThumbnails-master/pdf.gif" alt="Loading PDF" />
          </div>

          <div class="flex flex-col space-y-3">
            <pdf
              v-for="i in numPages"
              :key="i"
              :src="srcPdf"
              :page="i"
              :style="{ width: '100%' }"
            ></pdf>
          </div>
        </div>

        <div
          class="absolute bottom-0 max-h-full w-full px-4 pt-3.5 pb-5 bg-hgray9 rounded-bl-10px rounded-br-10px"
        >
          <div class="flex flex-col">
            <button
              @click="toRead"
              :disabled="this.caf.caf_letter.number !== 'General-Template' && !scrollDiv"
              class="btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center space-x-3.5 px-5"
            >
              <span class="text-sm font-medium">Continue</span>
              <IconArrow class="h-4 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="modal == 3" class="bg-hgray13 flex flex-col relative rounded-10px">
        <div
          class="absolute bg-hgray13 top-0 z-10 max-h-full w-full p-4 rounded-tl-10px rounded-tr-10px flex"
        >
          <div
            class="flex-none flex items-center space-x-2.5 cursor-pointer"
            @click="toPage(modal - 1)"
          >
            <IconBack class="h-6 w-6" />
            <span class="text-xl">CAF Form</span>
          </div>
          <div></div>
        </div>

        <div class="max-h-screen pt-13 pb-38 overflow-y-auto">
          <div class="w-full flex flex-col">
            <div
              class="flex flex-col relative pr-3 py-3 pl-6 bg-white rounded-md m-4"
              style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            >
              <BgCaf class="absolute h-5/6 right-2 top-0" />
              <div
                class="absolute left-0 bottom-0 top-0 w-2.5 bg-hservice rounded-tl-md rounded-bl-md"
              ></div>
              <div class="font-medium text-hservice">
                {{ caf.number }}
              </div>
              <div class="text-sm text-hgray8 mt-1">
                {{ caf.caf_letter ? caf.caf_letter.product_name : '-' }}
              </div>
              <div class="text-sm text-hgray8 mt-1">
                {{ caf.caf_type }}
              </div>
              <div
                class="text-xs w-min mt-1"
                :class="{
                  'px-2 py-1 font-semibold text-white bg-hred1 rounded-full':
                    caf.status === 'Created',
                  'px-2 py-1 font-semibold text-white bg-hred2 rounded-full': caf.status === 'Read',
                  'px-2 py-1 font-semibold text-white bg-hblue rounded-full':
                    caf.status === 'Submitted',
                  'px-2 py-1 font-semibold text-white bg-horange2 rounded-full':
                    caf.status === 'Revision',
                  'px-2 py-1 font-semibold text-black bg-hgray12 rounded-full':
                    caf.status === 'Rejected',
                  'px-2 py-1 font-semibold text-white bg-hgray12 rounded-full':
                    caf.status === 'Approved'
                }"
              >
                {{ caf.status }}
              </div>
              <div class="text-sm text-hgray8 mt-1" v-if="caf.revision">
                <div class="text-xs font-light">Note :</div>
                <div class="border border-solid border-gray-300 p-4" style="border-radius: 10px">
                  {{ caf.revision }}
                </div>
              </div>
            </div>

            <div v-if="cafChoiceQuestions.length > 0" class="bg-white p-4 mt-2">
              <div class="text-hblue text-13px font-medium mb-2">
                Select one of the options below
              </div>
              <div v-for="question in cafChoiceQuestions" :key="question.id" class="radio-group">
                <input
                  type="radio"
                  v-model="selectedQuestionId"
                  :value="question.id"
                  :id="'question_' + question.id"
                  :name="'questionGroup'"
                  @change="toChoiceQuestion"
                  class="radio-input text-sm"
                />
                <label :for="'question_' + question.id" class="radio-label">
                  {{ question.question }}
                </label>
              </div>
              <div v-if="hasInvalidChoice" class="text-red-500">
                Please select an option for all questions
              </div>
            </div>

            <div v-if="cafAnswerQuestions.length > 0" class="bg-white p-4 mt-4">
              <div class="text-hblue text-13px font-medium mb-2">Answer the question below</div>
              <div
                v-for="(x, index) in cafAnswerQuestions"
                :key="x.id"
                class="answer-question-group"
                style="margin-bottom: 20px"
              >
                <label
                  :for="'answer_question_' + x.id"
                  class="answer-question-label text-sm"
                  style="margin-bottom: 5px"
                  >{{ index + 1 }}. {{ x.question }}</label
                >
                <input
                  type="text"
                  :id="'answer_question_' + x.id"
                  v-model="x.answer"
                  class="input text-sm input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
                  @change="toAnswerQuestion"
                  style="margin-top: 5px"
                />
              </div>
              <div v-if="hasInvalidAnswers" class="text-red-500">Please answer all questions</div>
            </div>

            <!-- <div
              class="mt-5 rounded px-3 py-1 bg-hgray flex flex-col"
              v-for="d in caf.instruments"
              :key="d.id"
            >
              <div class="text-hgray8 text-sm font-medium">{{ d.type }}</div>
              <div class="text-hgray8 text-xs font-light">SN: {{ d.serial_number }}</div>
            </div> -->
          </div>

          <div class="mt-4 bg-white p-4 text-hblue pb-1">Please complete the form below.</div>
          <div class="pt-1 bg-white flex space-x-2 items-center px-4 pb-1">
            <IconDate class="h-3 w-3" />
            <div class="text-xs text-hgray10">{{ $moment().format('dddd, DD MMMM YYYY') }}</div>
          </div>

          <div class="p-4 bg-white flex flex-col space-y-4">
            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Company name</span>
              <input
                type="text"
                v-model="caf.customer_name"
                placeholder="Company name here..."
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
                :style="{ border: hasInvalidCustomerName ? '1px solid red' : '' }"
              />
              <div v-if="hasInvalidCustomerName" class="text-red-500">
                Please fill required fields
              </div>
            </div>

            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Address</span>
              <textarea
                v-model="caf.customer_address"
                placeholder="Address here..."
                class="textarea textarea-bordered text-sm transition rounded-lg w-full py-2 px-3 focus:ring-transparent focus:ring-0 h-20"
                :style="{ border: hasInvalidCustomerAddress ? '1px solid red' : '' }"
              ></textarea>
              <div v-if="hasInvalidCustomerAddress" class="text-red-500">
                Please fill required fields
              </div>
            </div>
            <div class="flex flex-col">
              <span class="text-sm mb-1 text-hgray7">Name of staff</span>
              <input
                type="text"
                v-model="staff_name"
                placeholder="Your name here..."
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
                :style="{ border: hasInvalidStaffName ? '1px solid red' : '' }"
              />
              <div v-if="hasInvalidStaffName" class="text-red-500">Please fill required fields</div>
            </div>

            <div class="flex flex-col" v-if="caf && caf.status == 'Submitted'">
              <span class="text-sm mb-1 text-hgray7">Company name</span>
              <input
                type="text"
                v-model="customer_name"
                disabled
                placeholder="Company name here..."
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
              />
            </div>

            <div class="flex flex-col form-control" v-else>
              <span class="text-sm mb-1 text-hgray7">Designation</span>
              <treeselect
                class="h-9"
                :multiple="false"
                v-model="caf_designation_id"
                :options="listDesignation"
                placeholder="Select designation first..."
                :clearable="false"
                @select="setDesignation"
                :style="{ border: hasInvalidDesignation ? '1px solid red' : '' }"
              />

              <input
                v-if="showOtherDesignationInput"
                type="text"
                v-model="otherDesignationText"
                placeholder="Enter other designation..."
                class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
                style="margin-top: 20px"
                :style="{ border: hasInvalidDesignation ? '1px solid red' : '' }"
              />
            </div>
            <div v-if="hasInvalidDesignation" class="text-red-500">Please fill required fields</div>
          </div>
        </div>

        <div
          class="absolute bottom-0 max-h-full w-full px-4 pt-4 pb-5 bg-hgray13 rounded-bl-10px rounded-br-10px"
        >
          <div class="flex flex-col">
            <div class="mb-4 flex" v-if="caf.caf_information_confirm !== null">
              <div class="form-control">
                <label class="label cursor-pointer space-x-3">
                  <input
                    type="checkbox"
                    v-model="agreeSubmit"
                    class="checkbox checkbox-primary"
                    @change="cafInformationConfirmId !== null ? toInformationConfirm() : null"
                  />
                  <span
                    class="label-text text-sm"
                    :class="
                      agreeSubmit
                        ? 'font-medium text-hgray8'
                        : !caf_designation_id
                        ? 'text-hgray5'
                        : 'text-hgray7'
                    "
                  >
                    {{ cafInformationConfirmText }}
                  </span>
                </label>
              </div>
            </div>
            <!--:disabled="cafInformationConfirmId !== null && !agreeSubmit"-->
            <button
              :disabled="!caf_designation_id"
              @click="checkSubmitForm"
              class="btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center space-x-3.5 px-5"
            >
              <span class="text-sm font-medium">Submit Form</span>
              <IconArrow class="h-4 w-5" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="modal == 4" class="bg-hgray13 flex flex-col rounded-10px">
        <div class="bg-white max-h-full w-full py-4 px-4 rounded-tl-10px rounded-tr-10px flex">
          <div class="flex-none">
            <span class="text-xl">CAF Form</span>
          </div>
          <div></div>
        </div>

        <div class="max-h-screen w-full bg-hblue10">
          <div class="flex flex-col py-4 px-5">
            <div class="text-xl font-semibold text-hservice">{{ caf.customer_name }}</div>
            <div class="mt-3 text-sm text-hgray8">
              {{ caf.customer_address }}
            </div>
          </div>
        </div>

        <div v-if="cafChoiceQuestions.length > 0" class="px-4 pb-4 pt-6">
          <div v-for="question in cafChoiceQuestions" :key="question.id">
            <div class="radio-group" v-if="selectedQuestionId == question.id">
              <input
                type="radio"
                v-model="selectedQuestionId"
                :value="question.id"
                :id="'question_' + question.id"
                :name="'questionGroup'"
                class="radio-input"
              />
              <label :for="'question_' + question.id" class="radio-label">
                {{ question.question }}
              </label>
            </div>
          </div>
        </div>

        <div v-if="cafAnswerQuestions.length > 0" class="px-4 pb-5">
          <div
            v-for="(x, index) in cafAnswerQuestions"
            :key="x.id"
            class="answer-question-group"
            style="margin-bottom: 20px"
          >
            <label
              :for="'answer_question_' + x.id"
              class="answer-question-label"
              style="margin-bottom: 5px"
              >{{ index + 1 }}. {{ x.question }}</label
            >
            <input
              type="text"
              readonly
              :id="'answer_question_' + x.id"
              v-model="x.answer"
              class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent focus:ring-0"
              style="margin-top: 5px"
            />
          </div>
        </div>

        <!-- <div
          class="mt-5 rounded px-3 py-1 bg-hgray flex flex-col"
          v-for="d in caf.instruments"
          :key="d.id"
        >
          <div class="text-hgray8 text-sm font-medium">{{ d.type }}</div>
          <div class="text-hgray8 text-xs font-light">SN: {{ d.serial_number }}</div>
        </div> -->

        <div class="max-h-full w-full p-4 flex flex-col bg-white">
          <div class="flex flex-col">
            <div class="text-sm text-hgray8">Name of staff</div>
            <div class="text-hgray8 font-medium">{{ staff_name }}</div>
            <!-- user.name-->
          </div>
          <div class="flex flex-col mt-4">
            <div class="text-sm text-hgray8">Designation</div>
            <div class="text-hgray8 font-medium">{{ penanggungJawab }}</div>
          </div>
          <div class="flex flex-col mt-4">
            <div class="text-sm text-hgray8">Date</div>
            <div class="text-hgray8 font-medium">{{ $moment().format('dddd, DD MMMM YYYY') }}</div>
          </div>
        </div>

        <div class="bg-hwarning3 mt-7 p-4 flex flex-col items-center justify-center">
          <div class="text-hwarning4 font-medium mb-2">Confirmation</div>
          <span class="text-sm text-hgray8">Make sure the data you enter is the correct data,</span>
          <span class="text-xs font-medium text-hgray7">are you sure to submit this form ?</span>
        </div>

        <div class="max-h-full w-full px-4 pt-4 pb-5 rounded-bl-10px rounded-br-10px">
          <div class="space-y-4 flex flex-col">
            <button
              @click="toSubmit"
              class="btn btn-primary hover:bg-hblue h-10 capitalize border-0 flex flex-items-center px-5"
            >
              <span class="text-13px font-medium">Yes, submit form</span>
            </button>
            <button
              @click="toPage(3)"
              class="px-5 btn btn-ghost capitalize h-9.3 hover:bg-transparent border-hblue text-htextlight text-13px font-medium"
            >
              Edit
            </button>
          </div>
        </div>
      </div>

      <div v-if="modal == 5" class="bg-white flex flex-col rounded-10px">
        <div class="w-full p-5 flex flex-col items-center justify-center">
          <img :src="CafDone" class="mt-3" />
          <div class="font-Nerko text-2xl text-hgreen1 mt-5">Congratulation</div>
          <div class="text-hgray5 mt-1.5 text-sm">CAF successfuly submitted</div>
        </div>

        <div class="max-h-full w-full px-5 mt-5 pb-12">
          <div class="space-x-5 flex items-center justify-center">
            <button
              @click="reloadPage"
              class="btn btn-ghost capitalize h-9.3 hover:bg-transparent border-hblue text-htextlight text-xs font-medium px-6"
            >
              Close
            </button>
            <button
              @click="toDetail"
              class="btn btn-primary hover:bg-hblue h-9.3 capitalize border-0 flex flex-items-center px-6"
            >
              <span class="text-xs font-normal">CAF detail</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import IconWarning from '@/assets/icons/warning.svg?inline'
import IconArrow from '@/assets/icons/arrow.svg?inline'
import IconBack from '@/assets/icons/icon-back.svg?inline'
import IconDate from '@/assets/icons/icon-date.svg?inline'
import IconDownload from '@/assets/icons/icon-downloadBlue.svg?inline'
import CafDone from '@/assets/images/caf-done.png'
import Treeselect from '@riophae/vue-treeselect'
import pdf from 'vue-pdf'
import BgCaf from '@/assets/icons/bg-caf.svg?inline'

// var loadingTask = pdf.createLoadingTask('https://pdfobject.com/pdf/sample-3pp.pdf')

export default {
  name: 'caf-form',
  components: {
    IconWarning,
    IconArrow,
    IconBack,
    pdf,
    Treeselect,
    IconDownload,
    IconDate,
    BgCaf
  },
  data() {
    return {
      modal: 2,
      oy: [3, 4],
      zoomList: [
        {
          id: 'md',
          label: 'Fit'
        },
        {
          id: 'xl',
          label: '2x'
        },
        {
          id: '3xl',
          label: '3x'
        },
        {
          id: 'screen',
          label: 'Full screen'
        }
      ],
      zoom: 'md',
      numPages: undefined,
      srcPdf: null,
      agreeCaf: false,
      agreeSubmit: false,
      scrollDiv: false,
      caf_designation_text: null,
      caf_designation_id: null,
      CafDone,
      selectedQuestionId: null,
      cafInformationConfirmId: null,
      otherDesignationText: null,
      hasInvalidAnswers: false,
      hasInvalidChoice: false,
      hasInvalidDesignation: false,
      staff_name: '',
      customer_name: '',
      customer_address: '',
      hasInvalidStaffName: false,
      hasInvalidCustomerName: false,
      hasInvalidCustomerAddress: false,
      loadingPdf: false
    }
  },
  methods: {
    ...mapActions('instrument', [
      'MODAL_CAF',
      'GET_DESIGNATION',
      'SUBMIT_CAF',
      'DETAIL_CAF',
      'READ_CAF',
      'SUBMIT_CAF_CHOICE_QUESTION',
      'SUBMIT_CAF_ANSWER_QUESTION',
      'SUBMIT_CAF_CONFIRM',
      'GET_CAF'
    ]),
    ...mapActions('modal', ['MODAL_CAF_DETAIL']),
    close() {
      this.MODAL_CAF(false)
    },
    toPage(val) {
      this.modal = val
    },

    loadPdf() {
      this.loadingPdf = true
      this.srcPdf = pdf.createLoadingTask(this.caf.file)
      this.srcPdf.promise
        .then(pdf => {
          this.numPages = pdf.numPages

          this.srcPdf._worker.destroy()
          this.loadingPdf = false
        })
        .catch(error => {
          console.error('Error loading PDF:', error)
          this.loadingPdf = false
        })
    },
    onScroll(event) {
      let el = event.srcElement

      if (!this.reachedBottom) {
        if (el.scrollTop >= el.scrollHeight - el.clientHeight - 100) {
          this.scrollDiv = true
        }
      }
    },
    error(err) {
      console.log(err)
    },
    async toSubmit() {
      let form = {
        id: this.caf.id,
        caf_designation_id: this.caf_designation_id,
        document_date: new Date().toISOString().slice(0, 10),
        defined_text: this.otherDesignationText,
        caf_staff: this.staff_name,
        caf_customer_name: this.caf.customer_name,
        caf_customer_address: this.caf.customer_address
      }

      let res = await this.SUBMIT_CAF(form)

      if (res) {
        this.DETAIL_CAF(this.caf.id)
        this.modal = 5
      }
    },
    toDetail() {
      this.MODAL_CAF_DETAIL(this.caf)
      this.toPage(2)
      this.close()
      this.GET_CAF(this.$route.query)
    },
    toRead() {
      if (this.caf.status == 'Created') this.READ_CAF(this.caf.id)
      this.toPage(3)
    },
    async toChoiceQuestion() {
      let form = {
        caf_document_id: this.caf.id,
        selected_question_id: this.selectedQuestionId
      }

      let res = await this.SUBMIT_CAF_CHOICE_QUESTION(form)

      if (res) {
        //this.toPage(3)
        this.hasInvalidChoice = false
      }
    },

    async setInitiallySelectedQuestionId() {
      const initiallySelectedQuestion = this.cafChoiceQuestions.find(
        question => question.is_selected
      )

      this.selectedQuestionId = initiallySelectedQuestion ? initiallySelectedQuestion.id : null
    },
    async toAnswerQuestion() {
      if (this.cafAnswerQuestions.length > 0) {
        let cafAnswerQuestionsData = this.cafAnswerQuestions.map(question => {
          return {
            id: question.id,
            answer: question.answer || ''
          }
        })

        let form = {
          caf_document_id: this.caf.id,
          caf_answer_questions: cafAnswerQuestionsData
        }
        let res = await this.SUBMIT_CAF_ANSWER_QUESTION(form)

        if (res) {
          //this.toPage(3)
        }
      }
    },
    async updateCafInformationConfirmId(id) {
      this.cafInformationConfirmId = id
    },
    async toInformationConfirm() {
      let form = {
        id: this.cafInformationConfirmId,
        caf_document_id: this.caf.id,
        is_confirmed: this.agreeSubmit
      }

      let res = await this.SUBMIT_CAF_CONFIRM(form)

      if (res) {
        //this.toPage(3)
      }
    },
    // validateAnswers() {
    //   this.cafAnswerQuestions.forEach(question => {
    //     question.valid = question.answer.trim() !== ''
    //   })
    // },

    // validateChoice() {
    //   this.cafChoiceQuestions.forEach(question => {
    //     question.valid = this.selectedQuestionId === question.id
    //   })
    //   this.hasInvalidChoice = !this.cafChoiceQuestions.every(question => question.valid)
    // },

    validateDesignation() {
      if (this.caf_designation_id === null) {
        this.hasInvalidDesignation = true
        return
      }
      if (this.caf_designation_id === 99) {
        if (this.otherDesignationText === null || this.otherDesignationText == '') {
          this.hasInvalidDesignation = true
          return
        }
      }

      if (this.staff_name === null || this.staff_name == '') {
        this.hasInvalidStaffName = true
        return
      }

      if (this.caf.customer_name === null || this.caf.customer_name == '') {
        this.hasInvalidCustomerName = true
        return
      }

      if (this.caf.customer_address === null || this.caf.customer_address == '') {
        this.hasInvalidCustomerAddress = true
        return
      }

      this.hasInvalidCustomerName = false
      this.hasInvalidCustomerAddress = false
      this.hasInvalidDesignation = false
      this.hasInvalidStaffName = false
      return true
    },
    resetModalState() {
      this.agreeSubmit = false
      this.updateCafInformationConfirmId(null)
      this.caf_designation_id = null
      this.otherDesignationText = null
    },

    validateConfirmation() {
      console.log(this.cafInformationConfirmId)
      if (this.cafInformationConfirmId && this.agreeSubmit == false) {
        return
      }
      this.agreeSubmit = true
      return true
    },

    checkSubmitForm() {
      let completion = true
      if (Array.isArray(this.cafAnswerQuestions) && this.cafAnswerQuestions.length) {
        let caq = this.cafAnswerQuestions

        for (let i = 0; i < caq.length; i++) {
          if (!caq[i].answer) {
            completion = false
            break
          }
        }
      }

      if (!this.validateDesignation() || !this.validateConfirmation() || !completion) {
        this.$toast.info('Please,complete the form.')
        return
      }

      this.toPage(4)
    },
    setDesignation(node) {
      this.caf_designation_text = node.label
    },
    reloadPage() {
      location.reload()
    }
  },
  watch: {
    modal: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.caf && val == 2) {
          this.scrollDiv = false
          this.loadPdf()
          this.resetModalState()
        }
      }
    },
    caf: {
      handler() {
        if (this.modal == 2) {
          this.scrollDiv = false
          this.loadPdf()
          this.setInitiallySelectedQuestionId()
          this.resetModalState()
        }
      },
      deep: true
    }
  },
  computed: {
    caf: {
      get: function () {
        return this.$store.state.instrument.caf
      },
      set: function (value) {
        this.MODAL_CAF(value)
      }
    },
    cafChoiceQuestions: function () {
      if (this.caf && this.caf.caf_choice_questions) {
        return this.caf.caf_choice_questions
      } else {
        return []
      }
    },
    cafAnswerQuestions: function () {
      if (this.caf && this.caf.caf_answer_questions) {
        return this.caf.caf_answer_questions
      } else {
        return []
      }
    },
    cafInformationConfirmText: function () {
      if (
        this.caf &&
        this.caf.caf_information_confirm &&
        this.caf.caf_information_confirm.confirm_text
      ) {
        this.updateCafInformationConfirmId(this.caf.caf_information_confirm.id)
        return this.caf.caf_information_confirm.confirm_text
      } else {
        this.updateCafInformationConfirmId(null)
        return ''
      }
    },
    showOtherDesignationInput() {
      return this.caf_designation_id === 99
    },
    penanggungJawab() {
      return this.caf_designation_id === 99 ? this.otherDesignationText : this.caf_designation_text
    },
    ...mapGetters('instrument', ['listDesignation']),
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted() {
    let auth = this.$store.state.auth
    if (auth.loggedIn) this.GET_DESIGNATION()

    this.setInitiallySelectedQuestionId()
    this.staff_name = this.user.name
    //this.customer_name = this.caf.customer_name
    //this.customer_address = this.caf.customer_address
  }
}
</script>

<style scoped>
.radio-group {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-input {
  margin-right: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #0073be;
  border-radius: 3px;
}

.radio-input:checked {
  background-color: #0073be;
}

.radio-label {
  flex: 1;
}
</style>
