import ApiRequest from '../../services/apiRequest'

export const GET_BANNERS = async context => {
  return await ApiRequest.getBanners()
    .then(response => {
      context.commit('GET_BANNERS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
