import ApiRequest from '../../services/apiRequest'
import TokenService from '../../services/tokenService'

export const LOGIN = async (context, credentials) => {
  return await ApiRequest.login(credentials)
    .then(response => {
      TokenService.setAccessToken(response.data.data.access_token)
      // context.dispatch('ME')
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const ME = async context => {
  return await ApiRequest.me()
    .then(response => {
      context.commit('ME', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const EDIT_USER = async (context, val) => {
  return await ApiRequest.editUser(val)
    .then(response => {
      context.dispatch('ME')
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const LOGOUT = async context => {
  await context.commit('LOGOUT')
  TokenService.removeToken()
  context.commit('ticket/AI_RESET', null, { root: true })
}

export const FCM_TOKEN = async (context, val) => {
  await context.commit('FCM_TOKEN', val)
}

export const GET_PLANNINGS = async (context, val) => {
  return await ApiRequest.getPlannings(val)
    .then(response => {
      context.commit('GET_PLANNINGS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_SWITCH_CUSTOMERS = async (context, val) => {
  return await ApiRequest.getSwitchCustomers(val)
    .then(response => {
      context.commit('GET_SWITCH_CUSTOMERS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const SWITCH_CUSTOMER = async (context, val) => {
  return await ApiRequest.switchCustomer(val)
    .then(response => {
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const STORE_FCM = async (context, val) => {
  return await ApiRequest.storeFcm(val)
    .then(response => {
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DELETE_FCM = async (context, val) => {
  return await ApiRequest.deleteFcm(val)
    .then(response => {
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const CHECK_INSTRUMENTS = async context => {
  return await ApiRequest.checkInstrument()
    .then(response => {
      context.commit('CHECK_INSTRUMENTS', response.data.data)
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
