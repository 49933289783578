export const GET_NOTIFICATIONS = (state, data) => {
  state.notifications = data
}

export const GET_BROADCASTS = (state, data) => {
  state.broadcast.data = data
}

export const GET_BROADCAST_COUNT = (state, data) => {
  state.broadcast.count = data
}

export const SET_BROADCAST = (state, data) => {
  state.broadcast.detail = data
}

export const GET_POINTS = (state, data) => {
  state.points = state.points.concat(data)
}

export const TOTAL_POINTS = (state, data) => {
  state.point = data.total
}
