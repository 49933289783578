import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

let firebaseConfig = {
  apiKey: 'AIzaSyCHVTTdyP7oENYtzgRqMaEWqaKLO1V_P6s',
  authDomain: 'i-care-customer.firebaseapp.com',
  databaseURL: 'https://i-care-customer.firebaseio.com',
  projectId: 'i-care-customer',
  storageBucket: 'i-care-customer.appspot.com',
  messagingSenderId: '167276704715',
  appId: '1:167276704715:web:3f28106468a07b01477c4e',
  measurementId: 'G-MBDRJTTML0'
}

firebase.initializeApp(firebaseConfig)

let messaging = null

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
} else {
  console.log('FCM not supported')
}

export default messaging
