import axios from 'axios'
import TokenService from './tokenService'
import store from '../store'
import { createToastInterface } from 'vue-toastification'

const pluginOptions = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false,
  maxToasts: 5,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false
    }

    return toast
  }
}

const toast = createToastInterface(pluginOptions)

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
  // withCredentials: true
})

instance.interceptors.request.use(
  config => {
    const token = TokenService.getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config

    if (err.response && err.response.data.status_code) {
      toast.error(err.response.data ? err.response.data.message : 'Something went wrong')
    } else toast.error('Terjadi kesalahan sistem')

    if (originalConfig.url !== '/token' && err.response.data.status_code == 401) {
      store.dispatch('auth/LOGOUT')
    }
    return Promise.reject(err)
  }
)

export default instance
