export const listNotification = state => {
  let data = state.notifications
  let temp = {
    data: [],
    count: 0
  }

  if (Array.isArray(data) && data.length) {
    data.forEach(d => {
      let str = d.body.split(' ')
      d.status = str[1]

      temp.data.push(d)

      if (!d.is_read) temp.count += 1
    })
  }

  return temp
}
