<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-2xl"
    >
      <div class="bg-white sm:rounded-21px modalUpAttach">
        <div class="py-5 flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <div class="flex justify-between items-center mb-2 px-7">
              <h3 class="text-2xl font-semibold">Detail Attachment</h3>
              <SvgClear class="cursor-pointer" @click="close" />
            </div>
            <div class="w-full mt-7 px-8">
              <object
                class="mb-7 mx-auto"
                :data="baseUrl + attachment.preview"
                v-if="ext.includes(attachment.extension)"
                style="max-height: 250px"
              />
              <div class="relative" v-else>
                <img :src="FilePreview" class="mx-auto mb-7" />
                <div class="absolute left-0 w-full text-center bottom-1/4">
                  <p class="text-hblue font-medium text-2xl">Please download file to view</p>
                </div>
              </div>

              <div class="attach-description bg-hblue3 text-center text-white">
                {{ attachment ? attachment.name : 'Not found' }}
              </div>
              <div class="mt-4 attach-description bg-white text-black" style="min-height: 80px">
                {{ attachment ? attachment.description : 'Not found' }}
              </div>

              <div class="flex space-x-2 justify-end mt-6 mb-3">
                <button
                  class="btn btn-ghost hover:border-transparent"
                  :disabled="loadDelete"
                  :class="loadDelete == true ? 'loading' : ''"
                  @click="toDelete"
                >
                  <TrashColored class="w-7.2 h-3.35" v-if="!loadDelete" />
                </button>
                <a
                  class="btn btn-ghost hover:border-transparent"
                  :disabled="loading"
                  :class="loading == true ? 'loading' : ''"
                  :href="baseUrl + attachment.preview"
                  download
                  :filename="attachment.name"
                  target="_blank"
                >
                  <DownloadColored class="w-8.39 h-3.35" v-if="!loading" />
                </a>
              </div>
            </div>

            <!-- content -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg?inline'
import TrashColored from '@/assets/icons/icon-delete.svg?inline'
import DownloadColored from '@/assets/icons/icon-downloadBlue.svg?inline'

import FilePreview from '@/assets/images/file.png'

export default {
  name: 'modal-detail-attachment',
  components: {
    SvgClear,
    TrashColored,
    DownloadColored
  },
  data() {
    return {
      FilePreview,
      loading: false,
      loadDelete: false,
      ext: ['png', 'pdf', 'jpg', 'bpm', 'jpeg', 'png'],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_DETAIL_ATTACHMENT']),
    ...mapActions('ticket', [
      'DOWNLOAD_ATTACHMENTS',
      'DELETE_TICKET_ATTACHMENTS',
      'GET_TICKET_ATTACHMENTS'
    ]),
    close() {
      this.MODAL_DETAIL_ATTACHMENT(false)
    },
    async downloadItem() {
      this.loading = true
      await this.DOWNLOAD_ATTACHMENTS(this.attachment)

      this.loading = false
    },
    async toDelete() {
      this.loadDelete = true
      await this.DELETE_TICKET_ATTACHMENTS({
        ticket_id: this.ticket.id,
        attachment_id: this.attachment.id
      })
      this.GET_TICKET_ATTACHMENTS(this.ticket.id)
      this.MODAL_DETAIL_ATTACHMENT(false)
      this.loadDelete = false
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.detailAttachment
      },
      set: function (value) {
        this.MODAL_DETAIL_ATTACHMENT(value)
      }
    },
    ...mapState({
      attachment: state => state.ticket.attachment,
      ticket: state => state.ticket.ticket
    })
  }
}
</script>
