<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50" @click="close"></div>

    <div
      class="absolute max-h-full w-full bottom-0 sm:inset-y-auto overflow-x-hidden overflow-y-auto max-w-2xl"
      :key="'attach-' + isOpen"
    >
      <div class="bg-white md:rounded-21px modalUpAttach">
        <div class="py-5 flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full">
            <div class="flex justify-between items-center mb-2 px-7">
              <h3 class="text-2xl font-semibold">Upload Attachment</h3>
              <SvgClear class="cursor-pointer" @click="close" />
            </div>

            <div class="w-full mt-5 px-8">
              <div
                class="py-3 px-4 bg-white rounded-20px"
                style="filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.09))"
              >
                <div class="flex">
                  <PdfColored class="mr-3 flex-none" />
                  <div class="pr-10">
                    <div class="font-semibold text-sm">
                      {{ image ? image.name : 'Selected file will show here' }}
                    </div>
                    <div class="text-xs">accept: image/*, pdf, zip, mp4, docx, txt (max 30MB)</div>
                  </div>
                </div>
                <div class="raltive">
                  <DeleteColored
                    class="h-5.2 w-5.2 cursor-pointer absolute right-4 top-4.12"
                    v-if="image"
                    @click="deleteImage"
                  />
                </div>
              </div>

              <div class="my-4" @dragover.prevent @drop="drop">
                <input
                  type="file"
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  :accept="acceptFile"
                />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div class="relative">
                    <!-- <object :data="img" v-if="img" style="max-height: 280px" /> -->
                    <img :src="FilePreview" class="mx-auto" />
                    <div class="absolute left-0 w-full text-center bottom-7 sm:bottom-18">
                      <p class="text-hblue font-medium text-lg sm:text-2xl" v-if="!image">
                        Choose a file or drop file here
                      </p>
                      <p class="text-hblue font-medium text-lg sm:text-2xl" v-else>
                        1 file selected
                      </p>
                    </div>
                  </div>
                </label>
              </div>

              <div class="font-semibold">Description</div>
              <div class="mt-1 form-control">
                <textarea
                  v-model="description"
                  placeholder="Type description attachment here..."
                  class="textarea h-20 w-full bg-hbluegray focus:bg-white"
                ></textarea>
              </div>

              <div class="flex space-x-3 justify-end mt-7 mb-3">
                <button
                  class="btn btn-outline capitalize hover:bg-hblue hover:border-transparent"
                  style="border-radius: 12px"
                  @click="close"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary text-white capitalize border-0 hover:bg-hblue"
                  style="border-radius: 12px"
                  @click="submit"
                  :disabled="loading"
                  :class="loading == true ? 'loading' : ''"
                >
                  Upload
                </button>
              </div>
            </div>

            <!-- content -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg?inline'
import PdfColored from '@/assets/icons/icon-pdf.svg?inline'
import DeleteColored from '@/assets/icons/icon-delete.svg?inline'

import FilePreview from '@/assets/images/file.png'

export default {
  name: 'modal-upload-attachment',
  components: {
    SvgClear,
    PdfColored,
    DeleteColored
  },
  data() {
    return {
      FilePreview,
      image: null,
      img: null,
      description: null,
      loading: false,
      errorForm: false
    }
  },
  methods: {
    ...mapActions('modal', ['MODAL_UPLOAD_ATTACHMENT']),
    ...mapActions('ticket', ['UPLOAD_TICKET_ATTACHMENTS']),
    close() {
      this.MODAL_UPLOAD_ATTACHMENT(false)
      this.image = null
      this.img = null
      this.description = null
    },
    onChange() {
      let filelist = [...this.$refs.file.files]

      if (filelist.size > 30000000) {
        this.$toast.info('Please, check your file again. Max upload 30MB')
        return
      }

      this.image = filelist[0]
      this.img = URL.createObjectURL(filelist[0])
    },

    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
    },
    deleteImage() {
      this.image = null
      this.img = null
    },
    async submit() {
      this.loading = true
      this.errorForm = false

      if (!this.image && !this.description) {
        this.loading = false
        this.errorForm = true
        return
      }

      let data = new FormData()

      data.set('ticket_id', this.ticket.id)
      data.set('description', this.description)
      data.append('file', this.image)

      let params = {
        ticket_id: this.ticket.id,
        data: data
      }

      let resUp = await this.UPLOAD_TICKET_ATTACHMENTS(params)
      this.loading = false

      if (resUp) {
        this.close()
      }
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.uploadAttachment
      },
      set: function (value) {
        this.MODAL_UPLOAD_ATTACHMENT(value)
      }
    },
    ...mapGetters('ticket', ['acceptFile']),
    ...mapState({
      attachment_types: state => state.ticket.attachment_types,
      ticket: state => state.ticket.ticket
    })
  }
}
</script>
