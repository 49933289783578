export const GET_CERTIFICATES = (state, data) => {
  state.certificates = data
}

export const SET_CERTIFICATE = (state, data) => {
  state.certificate = data
}

export const GET_CAF = (state, data) => {
  state.cafs = data
}

export const CAF_CAROUSEL = (state, data) => {
  state.carouselItems = data
}

export const DETAIL_CAF = (state, data) => {
  state.caf = data
}

export const GET_DESIGNATION = (state, data) => {
  state.designations = data
}

export const MODAL_CAF = (state, data) => {
  state.caf = data
}

export const GET_CERTIFICATES_TRAINING = (state, data) => {
  state.certificatesTraining = data
}
