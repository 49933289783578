import apiRequest from '../../services/apiRequest'
import ApiRequest from '../../services/apiRequest'

import moment from 'moment'

export const GET_TICKETS = async (context, params) => {
  return await ApiRequest.getTickets(params)
    .then(response => {
      context.commit('GET_TICKETS', response.data.data)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_FORM = (context, val) => {
  context.commit('TICKET_FORM', val)
}

export const GET_TICKET_TYPES = async context => {
  return await ApiRequest.getTicketType()
    .then(response => {
      context.commit('GET_TICKET_TYPES', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_TICKET_COUNT = async context => {
  return await ApiRequest.getTicketCount()
    .then(response => {
      context.commit('GET_TICKET_COUNT', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const PRE_OPEN_TICKET = async (context, val) => {
  return await ApiRequest.preOpenTicket(val)
    .then(response => {
      context.commit('PRE_OPEN_TICKET', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const CREATE_TICKET = async (context, val) => {
  context.commit('TICKET_DETAIL', { number: '' })
  return await ApiRequest.createTicket(val)
    .then(response => {
      context.dispatch('TICKET_DETAIL', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_TICKET_DETAIL = async (context, val) => {
  return await ApiRequest.detailTicket(val)
    .then(response => {
      context.dispatch('TICKET_DETAIL', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_CANCEL = async (context, val) => {
  return await ApiRequest.cancelTicket(val)
    .then(response => {
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_CLOSE = async (context, val) => {
  return await ApiRequest.closeTicket(val)
    .then(response => {
      context.commit('TICKET_TIME', new Date().getTime())
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const INVOICE_TICKET = async (context, val) => {
  return await ApiRequest.invoiceTicket(val.id)
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `invoice-${val.number}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DOWNLOAD_EXCEL = async (context, val) => {
  return await ApiRequest.downloadExcel(val)
    .then(response => {
      let fileTitle = 'Excel_Report' + val.date_from + val.date_to + '.xlsx'

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileTitle)
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_STATUSES = async (context, val) => {
  return await ApiRequest.getTicketStatuses(val)
    .then(response => {
      context.commit('TICKET_STATUSES', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_DETAIL = (context, val) => {
  context.commit('TICKET_DETAIL', val)
}

export const TICKET_ACTION = (context, val) => {
  context.commit('TICKET_ACTION', val)
}

export const TICKET_SERVICE_REPORT = async (context, val) => {
  return await ApiRequest.getTicketServiceReport(val)
    .then(response => {
      context.commit('TICKET_SERVICE_REPORT', response.data.data)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const TICKET_SPARE_PART = async (context, val) => {
  return await ApiRequest.getTicketSparePart(val)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_TICKET_CHAT = async (context, val) => {
  return await ApiRequest.getTicketChat(val)
    .then(response => {
      context.commit('ALL_CHATS', response.data.data)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const PUSH_CHATS = (context, val) => {
  context.commit('PUSH_CHATS', val)
}

export const SEND_TICKET_CHAT = async (context, val) => {
  return await ApiRequest.postTicketChat(val)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_TICKET_ATTACHMENTS = async (context, val) => {
  return await ApiRequest.getTicketAttachment(val)
    .then(response => {
      context.commit('GET_TICKET_ATTACHMENTS', response.data.data)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
export const ATTACHMENT_TYPES = async context => {
  return await ApiRequest.getAttachmentTypes()
    .then(response => {
      context.commit('ATTACHMENT_TYPES', response.data.data)
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const UPLOAD_TICKET_ATTACHMENTS = async (context, val) => {
  return await ApiRequest.uploadTicketAttachment(val)
    .then(response => {
      context.dispatch('GET_TICKET_ATTACHMENTS', val.ticket_id)
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DELETE_TICKET_ATTACHMENTS = async (context, val) => {
  return await ApiRequest.deleteTicketAttachment(val)
    .then(response => {
      context.dispatch('GET_TICKET_ATTACHMENTS', val.ticket_id)
      return response.data.message
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DOWNLOAD_ATTACHMENTS = async (context, val) => {
  return await ApiRequest.downloadTicketAttachment(val.preview)
    .then(response => {
      // console.log(response.data)
      // const blob = new Blob([response.data])
      // const link = document.createElement('a')
      // link.href = URL.createObjectURL(blob)
      // link.download = val.name
      // link.click()
      // URL.revokeObjectURL(link.href)
      return response.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const DETAIL_ATTACHMENT = (context, val) => {
  context.commit('DETAIL_ATTACHMENT', val)
}

export const ATTACHMENT_DELETE = (context, val) => {
  context.commit('ATTACHMENT_DELETE', val)
}

export const COMMENT_DRAFTS = async (context, val) => {
  return await ApiRequest.getCommentDraft(val)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_DIVISIONS = async context => {
  return await ApiRequest.getDivisions()
    .then(response => {
      context.commit('GET_DIVISIONS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_INSTRUMENTS = async (context, val) => {
  return await ApiRequest.getInstruments(val)
    .then(response => {
      context.commit('GET_INSTRUMENTS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_REQUEST_TYPE = async (context, val) => {
  return await ApiRequest.getRequestType(val)
    .then(response => {
      context.commit('GET_REQUEST_TYPE', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const GET_DEPARTMENTS = async context => {
  return await ApiRequest.getDepartments()
    .then(response => {
      context.commit('GET_DEPARTMENTS', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const AI_TYPED = async (context, val) => {
  context.commit('AI_TYPED', val)
}

export const AI_RESET = async context => {
  context.commit('AI_RESET')
}

export const AI_SEND = async (context, val) => {
  console.log('send: ', val)

  if (!val.greet)
    context.commit('AI_CHAT', {
      message: val.message,
      user: 1,
      time: moment().format('YYYY-MM-DD hh:mm')
    })
  // context.commit('AI_CHAT', { message: val.message, user: 1 })

  return await ApiRequest.aiSend(val)
    .then(response => {
      context.commit('AI_SEND', response.data.data)

      // console.log('send res: ', response.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const AI_HISTORY_CHAT = async (context, val) => {
  return await ApiRequest.aiHistory(val)
    .then(response => {
      context.commit('AI_HISTORY_CHAT', response.data.data)
      return true
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}

export const AI_CALLBACK = async (context, val) => {
  context.commit('AI_CHAT', val)
  context.commit('AI_TYPED', false)
}

export const PIC_IMG = async (context, val) => {
  return await apiRequest
    .getTicketImg(val)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      if (error) {
        // console.log(error)
      }
      return false
    })
}
