// import axios from 'axios'

// export const MODAL_CONFIRM_REQUEST = async (context, val) => {
//   context.commit('MODAL_CONFIRM_REQUEST', val)
// }

// export const MODAL_CONFIRM_NON_TECHNICAL = async (context, val) => {
//   context.commit('MODAL_CONFIRM_NON_TECHNICAL', val)
// }

export const MODAL_CONFIRM_NEW_TICKET = async (context, val) => {
  context.commit('MODAL_CONFIRM_NEW_TICKET', val)
}

export const DATA_TICKET = async (contex, val) => {
  contex.commit('DATA_TICKET', val)
}

export const MODAL_CREATED_TICKET = async (context, val) => {
  context.commit('MODAL_CREATED_TICKET', val)
}

export const MODAL_REVIEW_TICKET = async (context, val) => {
  context.commit('MODAL_REVIEW_TICKET', val)
}

export const MODAL_THX = async (context, val) => {
  context.commit('MODAL_THX', val)
}

export const MODAL_CONFIRM = async (context, val) => {
  context.commit('MODAL_CONFIRM', val)
}

export const MODAL_UPLOAD_ATTACHMENT = async (context, val) => {
  context.commit('MODAL_UPLOAD_ATTACHMENT', val)
}

export const MODAL_DETAIL_ATTACHMENT = async (context, val) => {
  context.commit('MODAL_DETAIL_ATTACHMENT', val)
}

export const MODAL_ERROR = async (context, val) => {
  context.commit('MODAL_ERROR', val)
}

export const MODAL_INSTRUMENT = async (context, val) => {
  context.commit('MODAL_INSTRUMENT', val)
}

export const MODAL_CERTIFICATE = async (context, val) => {
  context.commit('MODAL_CERTIFICATE', val)
}

export const MODAL_TICKET_TYPE = async (context, val) => {
  context.commit('MODAL_TICKET_TYPE', val)
}

export const MODAL_VIDEO = async (context, val) => {
  context.commit('MODAL_VIDEO', val)
}

export const MODAL_INFO = async (context, val) => {
  context.commit('MODAL_INFO', val)
}

export const MODAL_CAF_DETAIL = async (context, val) => {
  context.commit('MODAL_CAF_DETAIL', val)
}

export const MODAL_BOT = async (context, val) => {
  context.commit('MODAL_BOT', val)
}

export const MODAL_CAF_POP = async (context, val) => {
  context.commit('MODAL_CAF_POP', val)
}
