<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30" v-if="isOpen">
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div class="absolute max-h-full w-full bottom-0 sm:inset-y-auto max-w-xl">
      <div class="relative bg-white overflow-hidden sm:rounded-lg">
        <div class="flex justify-between items-start">
          <!--  -->
          <div class="max-h-full w-full px-8 py-6">
            <div class="flex justify-between items-center mb-6">
              <div>
                <span class="font-Roboto text-4xl text-htext font-bold" v-if="isOpen <= 2">
                  Technical Ticket
                </span>
                <span class="font-Roboto text-4xl text-htext font-bold" v-else>
                  Non Technical Ticket
                </span>
              </div>
              <img :src="SvgClear" class="cursor-pointer" @click="close" />
            </div>

            <div v-if="isOpen <= 2">
              <div class="font-Roboto font-semibold text-htext">Complaint</div>
              <div class="text-sm">
                Berisi laporan kerusakan/error pada alat/hasil QC/sample tidak sesuai/printer/UPS,
                hasil yang berkaitan dengan Eng./Apl./IT
                <br />
                <br />
                Contoh: -0.04 Mpa error, Water Leak Detected, UPS error, Printer error, QC Trend
                High/Low, Patient Result trend High/Low, Hasil tidak terkirim ke LIS/HCLab, HCLab
                offline
              </div>

              <div class="mt-5 font-Roboto font-semibold text-htext">Request</div>
              <div class="text-sm">
                Berisi permintaan yang diajukan ke Eng./Apl./IT
                <br />
                <br />
                Contoh: Permintaan metode PME/INAEQAS/PME ILKI, permintaan assay sheet/nilai
                kontrol/kalibrator, permintaan MSDS, permintaan nilai normal, permintaan jadwal
                maitenance/jadwal kalibrasi dll, permintaan visit oleh Apliksi/Eng/IT
              </div>
            </div>

            <div v-else>
              <div class="font-Roboto font-semibold text-htext">Complaint</div>

              <div class="text-sm">
                Berisi keluhan yang tidak berhubungan dengan hal teknis – dapat ditujukan ke
                departemen CCIT, Sales, SCM, Finance, Marketing
                <br />
                <br />
                Contoh: sudah mengajukan PO reagent/QC tapi barang belum datang, alat sudah diinstal
                tapi box belum diambil, teknisi/aplikasi tidak tuntas pekerjaannya, staf Sysmex
                kurang sopan dll
              </div>

              <div class="mt-5 font-Roboto font-semibold text-htext">Request</div>
              <div class="text-sm">
                Berisi permintaan yang tidak berhubungan dengan hal teknis – dapat ditujukan ke
                departemen CCIT, Sales, SCM, Finance, Marketing
                <br />
                <br />
                Contoh: Permintaan atlas hematology, Permintaan visit ke departement Sales,
                permintaan sertifikat event, permintaan sponsorship event
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SvgClear from '@/assets/icons/icon-close.svg'

export default {
  name: 'modal-ticket-type',
  data() {
    return {
      SvgClear
    }
  },

  methods: {
    ...mapActions('modal', ['MODAL_TICKET_TYPE']),
    close() {
      this.MODAL_TICKET_TYPE(false)
    }
  },
  computed: {
    isOpen: {
      get: function () {
        return this.$store.state.modal.ticket_type
      },
      set: function (value) {
        this.MODAL_TICKET_TYPE(value)
      }
    }
  }
}
</script>
